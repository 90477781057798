import { useQuery } from "react-query";
import notificationRepo from "../../API/LayoutApi/notification.repo";
import useRouteQuery from "../common/useRouteQuery";

function useNotificationList() {
  const [userId, setUserId] = useRouteQuery("", "user_id", (v) => String(v));
  const [page, setPage] = useRouteQuery(1, "page", (v) => Number(v));
  const [isRead, setIsRead] = useRouteQuery("", "is_read", (v) => String(v));

  const { isLoading, isFetching, data, refetch } = useQuery({
    queryKey: ["notifications", page, userId, isRead],
    queryFn: () =>
      notificationRepo.getNotifications({
        page,
        perPage: 10,
        user_id: userId ? userId : undefined,
        is_read: isRead ? JSON.parse(isRead) : undefined,
      }),
    refetchOnWindowFocus: false,
  });

  return {
    userId,
    setUserId,
    page,
    setPage,
    isRead,
    setIsRead,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

export default useNotificationList;
