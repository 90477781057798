import { CSSProperties, useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import { Select } from "antd";
import categoryRepo from "../../API/LayoutApi/category.repo";

type TCategoryValue = string | string[];

function CategorySelect({
  value,
  onChange,
  multiple = false,
  style,
}: {
  value?: TCategoryValue;
  onChange?(value: TCategoryValue): void;
  multiple?: boolean;
  style?: CSSProperties;
} = {}) {
  const [search, setSearch] = useState("");

  const { isFetching, data } = useQuery({
    queryKey: ["categories", search],
    queryFn: () => categoryRepo.getCategories({ page: 1, perPage: 10, search }),
    refetchOnWindowFocus: false,
  });

  const [searchValue, setSearchValue] = useState("");

  const options = useMemo(() => {
    if (data?.data) {
      return data.data.map((category) => ({
        label: category.name,
        value: category.id,
      }));
    }
    return [];
  }, [data]);

  const debounceChangeSearch = useCallback(debounce(setSearch, 500), []);

  function onSearchFromSelect(val: string) {
    setSearchValue(val);
    debounceChangeSearch(val);
  }

  const filterOption = useCallback(
    (input: string, option?: { label: string; value: string }) =>
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase()),
    []
  );

  return (
    <Select
      showSearch
      placeholder="Search category"
      optionFilterProp="children"
      searchValue={searchValue}
      value={value}
      onChange={onChange}
      onSearch={onSearchFromSelect}
      options={options}
      filterOption={filterOption}
      loading={isFetching}
      mode={multiple ? "multiple" : undefined}
      style={style}
      allowClear
    />
  );
}

export default CategorySelect;
