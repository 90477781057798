import { MenuProps } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import {
  DesktopOutlined,
  MessageOutlined,
  HistoryOutlined,
  MenuUnfoldOutlined,
  TeamOutlined,
  StockOutlined,
  UserOutlined,
  CreditCardOutlined,
  CoffeeOutlined,
  PartitionOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import UsersPage from "../Components/User/UsersPage";
import DevicePage from "../Components/Device/DevicePage";
import LocationPage from "../Components/Location/LocationPage";
import CardPage from "../Components/Card/CardPage";
import BusinessPage from "../Components/Business/BusinessPage";
import CategoryPage from "../Components/Category/CategoryPage";
import StatePage from "../Components/State/StatePage";
import BusinessUserPage from "../Components/BusinessUser/BusinessUserPage";
import HistoryPage from "../Components/History/HistoryPage";
import ProviderPage from "../Components/Providers/ProviderPage";
import ProviderFormPage from "../Components/Providers/ProviderFormPage";
import NotificationPage from "../Components/Notifications/NotificationPage";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

export const allMenu: MenuItem[] = [
  getItem(<Link to="/">Device</Link>, "/", <DesktopOutlined />),
  getItem(
    <Link to="/locations">Location</Link>,
    "/locations",
    <WarningOutlined />
  ),
  getItem(<Link to="/users">Users</Link>, "/users", <UserOutlined />),
  getItem(<Link to="/card">Card</Link>, "/card", <CreditCardOutlined />),
  getItem(
    <Link to="/providers">Providers</Link>,
    "/providers",
    <PartitionOutlined />
  ),
  getItem(<Link to="/state">State</Link>, "/state", <CoffeeOutlined />),
  getItem(
    <Link to="/notifications">Notifications</Link>,
    "/notifications",
    <MessageOutlined />
  ),
  getItem(<Link to="/business">Business</Link>, "/business", <StockOutlined />),
  getItem(
    <Link to="/business-user">Business User</Link>,
    "/business-user",
    <TeamOutlined />
  ),
  getItem(
    <Link to="/category">Category</Link>,
    "/category",
    <MenuUnfoldOutlined />
  ),
  getItem(<Link to="/history">History</Link>, "/history", <HistoryOutlined />),
];

export const items = [
  {
    path: "/",
    component: <DevicePage />,
    key: "/",
  },
  {
    path: "/locations",
    component: <LocationPage />,
    key: "/locations",
  },
  {
    path: "/users",
    component: <UsersPage />,
    key: "/users",
  },
  {
    path: "/card",
    component: <CardPage />,
    key: "/card",
  },
  {
    path: "/providers",
    component: <ProviderPage />,
    key: "/providers",
  },
  {
    path: "/provider-form/:id",
    component: <ProviderFormPage />,
    key: "/provider-form/:id",
  },
  {
    path: "/state",
    component: <StatePage />,
    key: "/state",
  },
  {
    path: "/notifications",
    component: <NotificationPage />,
    key: "/notifications",
  },
  {
    path: "/business",
    component: <BusinessPage />,
    key: "/business",
  },
  {
    path: "/business-user",
    component: <BusinessUserPage />,
    key: "/business-user",
  },
  {
    path: "/category",
    component: <CategoryPage />,
    key: "/category",
  },
  {
    path: "/history",
    component: <HistoryPage />,
    key: "/history",
  },
];
