import { AxiosResponse } from "axios";
import { TState } from "../../types/State/TState";
import { TPagination } from "../../types/common/TPagination";
import instance from "../api";

export type TGetStatesParams = {
  search?: string;
  is_active?: boolean;
  page: number;
  perPage: number;
};

const stateRepo = {
  baseUrl: "admin/state",

  getStates(filterObject: TGetStatesParams) {
    const params: TGetStatesParams = { ...filterObject };

    if (!!filterObject.search) params.search = filterObject.search;
    if (!!filterObject.is_active) params.is_active = filterObject.is_active;

    return instance
      .get<TPagination<TState[]>>(this.baseUrl, {
        params,
      })
      .then((response) => response.data);
  },

  addState(name: string) {
    return instance
      .post<{ name: string }, AxiosResponse<TState>>(this.baseUrl, { name })
      .then((res) => res.data);
  },

  editState(id: string, formValues: Omit<TState, "id">) {
    return instance
      .put<Omit<TState, "id">, AxiosResponse<TState>>(
        `${this.baseUrl}/${id}`,
        formValues
      )
      .then(() => {});
  },

  deleteState(id: string) {
    return instance.delete(`${this.baseUrl}/${id}`).then(() => {});
  },
};

export default stateRepo;
