import { TAddUser } from "./../../types/User/TAddUser";
import { TUser } from "../../types/User/TUser";
import { TPagination } from "../../types/common/TPagination";
import instance from "../api";

export type TGetUsersParams = {
  email?: string;
  phone?: string;
  search?: string;
  is_active?: boolean;
  page: number;
  perPage: number;
};

const userRepo = {
  baseUrl: "admin/users",
  getUsers(filterObject: TGetUsersParams) {
    const params: TGetUsersParams = { ...filterObject };

    if (!!filterObject.email) params.email = filterObject.email;
    if (!!filterObject.phone) params.phone = filterObject.phone;
    if (!!filterObject.search) params.search = filterObject.search;
    if (!!filterObject.is_active) params.is_active = filterObject.is_active;

    return instance
      .get<TPagination<TUser[]>>(this.baseUrl, {
        params,
      })
      .then((response) => response.data);
  },

  addUser(addUserObject: TAddUser) {
    return instance.post(this.baseUrl, addUserObject).then(() => {});
  },

  userEdit(id: string, userEditObject: Omit<TUser, "id">) {
    const updatingUser = { ...userEditObject };
    if (!!updatingUser.password?.trim()) {
      delete updatingUser.password;
    }
    return instance.put(`${this.baseUrl}/${id}`, userEditObject).then(() => {});
  },

  userDelete(id: string) {
    return instance.delete(`${this.baseUrl}/${id}`).then(() => {});
  },
};

export default userRepo;
