import { Button, Row, Space, TablePaginationConfig, Tooltip } from "antd";
import useDeviceList from "../../Hooks/Device/useDeviceList";
import { SyncOutlined } from "@ant-design/icons";
import DeviceTable from "./DeviceTable";
import { FilterValue } from "antd/es/table/interface";
import { useState } from "react";
import { TDevice } from "../../types/Device/TDevice";
import DeviceEditForm from "./DeviceEditForm";
import deviceRepo from "../../API/LayoutApi/device.repo";
import UserAutoComplete from "../User/UserAutoComplete";

function DevicePage() {
  const {
    userId,
    setUserId,
    setPage,
    setIsActive,
    isLoading,
    isFetching,
    data,
    refetch,
  } = useDeviceList();

  function onChangeTable(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) {
    setPage(pagination.current as number);
    if (
      !filters.is_active ||
      (filters.is_active && filters.is_active.length === 2)
    ) {
      setIsActive("");
    } else if (filters.is_active && filters.is_active.length === 1) {
      setIsActive(String(filters.is_active[0]));
    }
  }

  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editDevice, setEditDevice] = useState<TDevice>();
  function openEditForm(device: TDevice) {
    setEditDevice(device);
    setEditFormOpen(true);
  }

  return (
    <div>
      <Row align="top" justify="space-between" style={{ marginBottom: 16 }}>
        <Space>
          <UserAutoComplete value={userId} onChange={setUserId} />
        </Space>
        <Space>
          <Tooltip title="Refresh">
            <Button
              onClick={() => refetch()}
              loading={isFetching}
              icon={<SyncOutlined />}
            />
          </Tooltip>
        </Space>
      </Row>
      <DeviceTable
        data={data}
        isLoading={isLoading}
        onChange={onChangeTable}
        onEdit={openEditForm}
        deleteDevice={(id: string) => deviceRepo.deleteDevice(id)}
        refetch={refetch}
      />

      {editDevice && (
        <DeviceEditForm
          initialValue={editDevice}
          open={editFormOpen}
          setOpen={setEditFormOpen}
          onSubmit={() => refetch()}
        />
      )}
    </div>
  );
}

export default DevicePage;
