import { useMutation } from "react-query";
import { TDevice } from "../../types/Device/TDevice";
import deviceRepo from "../../API/LayoutApi/device.repo";
import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  message,
} from "antd";

function DeviceEditForm({
  initialValue,
  open,
  setOpen,
  onSubmit,
}: {
  initialValue: TDevice;
  open: boolean;
  setOpen(value: boolean): void;
  onSubmit(editObject: TDevice): void;
}) {
  const { isLoading, mutateAsync: mutateEdit } = useMutation({
    mutationFn: ({
      id,
      editObject,
    }: {
      id: string;
      editObject: Omit<TDevice, "id">;
    }) => deviceRepo.deviceEdit(id, editObject),
    onSuccess: () => {
      message.success("User edited successfully!");
      setOpen(false);
    },
    onError: (error: string) => {
      message.error(error);
    },
  });

  function onFinishForm(values: Omit<TDevice, "id">) {
    mutateEdit({ id: initialValue.id, editObject: values }).then(() => {
      onSubmit({ id: initialValue.id, ...values });
    });
  }

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title="Edit User"
      footer={null}
      width={800}
      maskClosable={false}
    >
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
          initialValues={initialValue}
          onFinish={onFinishForm}
        >
          <Form.Item label="App version" name="app_version">
            <Input />
          </Form.Item>
          <Form.Item label="Device info" name="device_info">
            <Input />
          </Form.Item>
          <Form.Item label="FCM token" name="fcm_token">
            <Input />
          </Form.Item>
          <Form.Item label="Model" name="model">
            <Input />
          </Form.Item>
          <Form.Item label="One signal user id" name="one_signal_user_id">
            <Input />
          </Form.Item>
          <Form.Item label="Status" name="is_active">
            <Radio.Group>
              <Radio value={true}>Active</Radio>
              <Radio value={false}>Not active</Radio>
            </Radio.Group>
          </Form.Item>
          <Row justify="end">
            <Space>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default DeviceEditForm;
