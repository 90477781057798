import { useMutation } from "react-query";
import { TUser } from "../../types/User/TUser";
import userRepo from "../../API/LayoutApi/user.repo";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  message,
} from "antd";

type TUserEditFormProps = {
  initialValue: TUser;
  open: boolean;
  setOpen: (value: boolean) => void;
  onSubmit: (userEditObject: TUser) => void;
};

function UserEditForm({
  initialValue,
  open,
  setOpen,
  onSubmit,
}: TUserEditFormProps) {
  const { isLoading, mutateAsync: mutateUserEdit } = useMutation({
    mutationFn: ({
      id,
      userEditObject,
    }: {
      id: string;
      userEditObject: Omit<TUser, "id">;
    }) => userRepo.userEdit(id, userEditObject),
    onSuccess: () => {
      message.success("User edited successfully!");
      setOpen(false);
    },
    onError: (error: string) => {
      message.error(error);
    },
  });

  function onFinishForm(values: Omit<TUser, "id">) {
    mutateUserEdit({ id: initialValue.id, userEditObject: values }).then(() => {
      onSubmit({ id: initialValue.id, ...values });
    });
  }

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title="Edit User"
      footer={null}
      width={800}
      maskClosable={false}
    >
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
          onFinish={onFinishForm}
          initialValues={initialValue}
        >
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col span={12}>
              <Form.Item<TUser>
                label="Username"
                name="username"
                rules={[
                  { required: true, message: "Please input your user name!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<TUser> label="Password" name="password">
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<TUser>
                label="First name"
                name="first_name"
                rules={[
                  { required: true, message: "Please input your first name!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<TUser>
                label="Second name"
                name="second_name"
                rules={[
                  { required: true, message: "Please input your second name!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<TUser>
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<TUser>
                label="Phone"
                name="phone"
                rules={[
                  { required: true, message: "Please input your phone!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<TUser> label="Address" name="address">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<TUser> label="Date of birth" name="dateOfBirth">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item<TUser> label="Gender" name="gender">
                <Radio.Group>
                  <Radio value="Male">Male</Radio>
                  <Radio value="Female">Female</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Status" name="is_active">
                <Radio.Group>
                  <Radio value={true}>Active</Radio>
                  <Radio value={false}>Not active</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Notes" name="notes">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Space>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default UserEditForm;
