import { Button, Row, Space, TablePaginationConfig, Tooltip } from "antd";
import useUserList from "../../Hooks/Users/useUserList";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import SearchInput from "../../Utils/SearchInput";
import UsersTable from "./UsersTable";
import UserAddForm from "./UserAddForm";
import { useState } from "react";
import { TUser } from "../../types/User/TUser";
import UserEditForm from "./UserEditForm";
import userRepo from "../../API/LayoutApi/user.repo";
import { FilterValue } from "antd/es/table/interface";

function UsersPage() {
  const {
    search,
    setSearch,
    phone,
    setPhone,
    email,
    setEmail,
    setPage,
    setIsActive,
    data,
    refetch,
    isLoading,
    isFetching,
  } = useUserList();

  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editUser, setEditUser] = useState<TUser>();

  function onChangeTable(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) {
    setPage(pagination.current as number);
    if (
      !filters.is_active ||
      (filters.is_active && filters.is_active.length === 2)
    ) {
      setIsActive("");
    } else if (filters.is_active && filters.is_active.length === 1) {
      setIsActive(String(filters.is_active[0]));
    }
  }

  function openEditForm(user: TUser) {
    setEditUser(user);
    setEditFormOpen(true);
  }

  return (
    <div>
      <Row align="top" justify="space-between" style={{ marginBottom: 16 }}>
        <Space>
          <SearchInput
            value={search}
            onDebounceChange={setSearch}
            placeholder="Search by user name"
            style={{ width: 250 }}
          />
          <SearchInput
            value={email}
            onDebounceChange={setEmail}
            placeholder="Search by email"
            style={{ width: 250 }}
          />
          <SearchInput
            value={phone}
            onDebounceChange={setPhone}
            placeholder="Search by phone"
            style={{ width: 250 }}
          />
        </Space>
        <Space>
          <Button
            onClick={() => setAddFormOpen(true)}
            type="primary"
            icon={<PlusOutlined />}
          >
            Add User
          </Button>
          <Tooltip title="Refresh">
            <Button
              onClick={() => refetch()}
              loading={isFetching}
              icon={<SyncOutlined />}
            />
          </Tooltip>
        </Space>
      </Row>
      <UsersTable
        data={data}
        isLoading={isLoading || isFetching}
        onEdit={openEditForm}
        onChange={onChangeTable}
        deleteUser={(id: string) => userRepo.userDelete(id)}
        refetch={() => refetch()}
      />

      <UserAddForm
        open={addFormOpen}
        setOpen={setAddFormOpen}
        onSubmit={() => refetch()}
      />
      {editUser && (
        <UserEditForm
          initialValue={editUser}
          open={editFormOpen}
          setOpen={setEditFormOpen}
          onSubmit={() => refetch()}
        />
      )}
    </div>
  );
}

export default UsersPage;
