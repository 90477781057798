import { Button, Row, Space, TablePaginationConfig, Tooltip } from "antd";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import SearchInput from "../../Utils/SearchInput";
import { useState } from "react";
import { FilterValue } from "antd/es/table/interface";
import useBusinessUserList from "../../Hooks/BusinessUser/useBusinessUserList";
import BusinessSelect from "../Business/BusinessSelect";
import BusinessUserTable from "./BusinessUserTable";
import businessUserRepo from "../../API/LayoutApi/businessUser.repo";
import { TBusinessUser } from "../../types/BusinessUser/TBusinessUser";
import BusinessUserForm from "./BusinessUserForm";

function BusinessUserPage() {
  const {
    businessId,
    setBusinessId,
    search,
    setSearch,
    phone,
    setPhone,
    email,
    setEmail,
    setPage,
    setIsActive,
    data,
    refetch,
    isLoading,
    isFetching,
  } = useBusinessUserList();

  function onChangeTable(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) {
    setPage(pagination.current as number);
    if (
      !filters.is_active ||
      (filters.is_active && filters.is_active.length === 2)
    ) {
      setIsActive("");
    } else if (filters.is_active && filters.is_active.length === 1) {
      setIsActive(String(filters.is_active[0]));
    }
  }

  const [formOpen, setFormOpen] = useState(false);
  const [editingBusinessUser, setEditingBusinessUser] =
    useState<TBusinessUser>();

  function openForm(businessUser?: TBusinessUser) {
    setFormOpen(true);
    setEditingBusinessUser(businessUser);
  }

  return (
    <div>
      <Row align="top" justify="space-between" style={{ marginBottom: 16 }}>
        <Space>
          <BusinessSelect value={businessId} onChange={setBusinessId} />
          <SearchInput
            value={search}
            onDebounceChange={setSearch}
            placeholder="Search by user name"
          />
          <SearchInput
            value={email}
            onDebounceChange={setEmail}
            placeholder="Search by email"
          />
          <SearchInput
            value={phone}
            onDebounceChange={setPhone}
            placeholder="Search by phone"
          />
        </Space>
        <Space>
          <Button
            onClick={() => openForm()}
            type="primary"
            icon={<PlusOutlined />}
          >
            Add business user
          </Button>
          <Tooltip title="Refresh">
            <Button
              onClick={() => refetch()}
              loading={isFetching}
              icon={<SyncOutlined />}
            />
          </Tooltip>
        </Space>
      </Row>
      <BusinessUserTable
        data={data}
        isLoading={isLoading || isFetching}
        onChange={onChangeTable}
        onEdit={openForm}
        deleteUser={(id: string) => businessUserRepo.businessUserDelete(id)}
        refetch={() => refetch()}
      />

      {formOpen && (
        <BusinessUserForm
          open={formOpen}
          initialValues={editingBusinessUser}
          id={editingBusinessUser?.id}
          setOpen={setFormOpen}
          onSubmit={() => refetch()}
        />
      )}
    </div>
  );
}

export default BusinessUserPage;
