import { Input } from "antd";
import { debounce } from "lodash";
import {
  ChangeEvent,
  useState,
  type CSSProperties,
  useEffect,
  useCallback,
} from "react";

function SearchInput({
  value,
  onChange,
  onDebounceChange,
  placeholder,
  disabled,
  style,
}: {
  value?: string;
  onChange?: (val: string) => void;
  onDebounceChange: (val: string) => void;
  placeholder?: string;
  disabled?: boolean;
  style?: CSSProperties;
}) {
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  const debounceChange = useCallback(
    debounce((value: string) => onDebounceChange(value), 500),
    []
  );
  const handeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
    debounceChange(event.target.value);
  };

  return (
    <Input.Search
      value={state}
      onChange={handeInput}
      placeholder={placeholder}
      disabled={disabled}
      style={style}
    />
  );
}

export default SearchInput;
