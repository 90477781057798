import { TCategoryForm } from "../../types/Category/TCategoryForm";
import { AxiosResponse } from "axios";
import { TCategory } from "../../types/Category/TCategory";
import { TPagination } from "../../types/common/TPagination";
import instance from "../api";

type TGetCategoriesParams = {
  search?: string;
  is_active?: boolean;
  page: number;
  perPage: number;
};

const categoryRepo = {
  baseUrl: "admin-category",

  getCategories(filterObject: TGetCategoriesParams) {
    const params: TGetCategoriesParams = { ...filterObject };

    if (!!filterObject.search) params.search = filterObject.search;
    if (!!filterObject.is_active) params.is_active = filterObject.is_active;

    return instance
      .get<TPagination<TCategory[]>>(this.baseUrl, {
        params,
      })
      .then((response) => response.data);
  },

  addCategory(categoryForm: TCategoryForm) {
    return instance
      .post<TCategoryForm, AxiosResponse<TCategory>>(this.baseUrl, categoryForm)
      .then((res) => res.data);
  },

  editCategory(id: string, categoryForm: TCategoryForm) {
    return instance
      .put<TCategoryForm, AxiosResponse<TCategory>>(
        `${this.baseUrl}/${id}`,
        categoryForm
      )
      .then(() => {});
  },

  deleteCategory(id: string) {
    return instance.delete(`${this.baseUrl}/${id}`).then(() => {});
  },

  fileUpload(id: string, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return instance
      .post(`${this.baseUrl}/file-upload/${id}`, formData)
      .then(() => {});
  },

  deleteFile(id: string) {
    return instance.delete(`${this.baseUrl}/delete-file/${id}`);
  },
};

export default categoryRepo;
