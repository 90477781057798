import { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import providerRepo from "../../API/LayoutApi/provider.repo";
import { debounce } from "lodash";
import { Select } from "antd";

type TProviderValue = string | string[];

function ProviderSelect({
  value,
  onChange,
  multiple = false,
}: {
  value?: TProviderValue;
  onChange?(value: TProviderValue): void;
  multiple?: boolean;
} = {}) {
  const [search, setSearch] = useState("");

  const { isFetching, data } = useQuery({
    queryKey: ["providers", search],
    queryFn: () => providerRepo.getProviders({ page: 1, perPage: 10, search }),
    refetchOnWindowFocus: false,
  });

  const [searchValue, setSearchValue] = useState("");

  const options = useMemo(() => {
    if (data?.data) {
      return data.data.map((provider) => ({
        label: provider.name,
        value: provider.id,
      }));
    }
    return [];
  }, [data]);

  const debounceChangeSearch = useCallback(debounce(setSearch, 500), []);

  function onSearchFromSelect(val: string) {
    setSearchValue(val);
    debounceChangeSearch(val);
  }

  const filterOption = useCallback(
    (input: string, option?: { label: string; value: string }) =>
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase()),
    []
  );

  return (
    <Select
      showSearch
      placeholder="Search provider"
      optionFilterProp="children"
      searchValue={searchValue}
      value={value}
      onChange={onChange}
      onSearch={onSearchFromSelect}
      options={options}
      filterOption={filterOption}
      loading={isFetching}
      allowClear
      mode={multiple ? "multiple" : undefined}
    />
  );
}

export default ProviderSelect;
