import {
  Button,
  Modal,
  Space,
  Table,
  TablePaginationConfig,
  Tooltip,
} from "antd";
import { TPagination } from "../../types/common/TPagination";
import { FilterValue } from "antd/es/table/interface";
import { QueryObserverResult } from "react-query";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { THistory } from "../../types/History/THistory";

function HistoryTable({
  data,
  onEdit,
  onChange,
  isLoading,
  deleteData,
  refetch,
}: {
  data?: TPagination<THistory[]>;
  onEdit(editingData: THistory): void;
  onChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ): void;
  isLoading?: boolean;
  deleteData(id: string): Promise<void>;
  refetch(): Promise<QueryObserverResult<TPagination<THistory[]>, unknown>>;
}) {
  return (
    <Table
      onChange={onChange}
      loading={isLoading}
      pagination={{
        total: data?.total,
      }}
      columns={[
        {
          title: "#",
          dataIndex: "index",
          key: "no",
        },
        {
          title: "name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "user id",
          dataIndex: "user_id",
          key: "user_id",
        },
        {
          title: "cheque id",
          dataIndex: "cheque_id",
          key: "cheque_id",
        },
        {
          title: "Actions",
          dataIndex: "action",
          key: "action",
          render: ({ id }: { id: string }) => (
            <Space>
              <Tooltip title="Edit History">
                <Button
                  onClick={() => {
                    if (data?.data) {
                      const device = data.data.find(
                        (device) => device.id === id
                      );
                      if (device) {
                        onEdit(device);
                      }
                    }
                  }}
                  icon={<EditOutlined />}
                />
              </Tooltip>

              <Tooltip title="Delete History">
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    Modal.confirm({
                      title: "History",
                      icon: <ExclamationCircleFilled />,
                      content: "Do you want to delete this history ?",
                      onOk: () => deleteData(id).then(() => refetch()),
                    })
                  }
                  danger
                />
              </Tooltip>
            </Space>
          ),
        },
      ]}
      dataSource={data?.data?.map((u: THistory, i: number) => ({
        index: i + 1,
        ...u,
        action: { id: u.id },
        key: u.id,
      }))}
    />
  );
}

export default HistoryTable;
