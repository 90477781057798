import { TAddCard } from "./../../types/Card/TAddCard";
import { TCard } from "../../types/Card/TCard";
import { TPagination } from "../../types/common/TPagination";
import instance from "../api";

export type TGetCardsParams = {
  user_id?: string;
  type?: "bank" | "card";
  is_active?: boolean;
  page: number;
  perPage: number;
};

const cardRepo = {
  baseUrl: "admin/cards",
  getCards(filterObject: TGetCardsParams) {
    const params: TGetCardsParams = { ...filterObject };

    if (!!filterObject.user_id) params.user_id = filterObject.user_id;
    if (!!filterObject.type) params.type = filterObject.type;
    if (!!filterObject.is_active) params.is_active = filterObject.is_active;

    return instance
      .get<TPagination<TCard[]>>(this.baseUrl, {
        params,
      })
      .then((response) => response.data);
  },

  addCard(addObject: TAddCard) {
    return instance.post(this.baseUrl, addObject).then(() => {});
  },

  cardEdit(id: string, editObject: Omit<TCard, "id">) {
    return instance.put(`${this.baseUrl}/${id}`, editObject).then(() => {});
  },

  cardDelete(id: string) {
    return instance.delete(`${this.baseUrl}/${id}`).then(() => {});
  },
};

export default cardRepo;
