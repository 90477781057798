import { useState } from "react";
import useStateList from "../../Hooks/State/useStateList";
import { TState } from "../../types/State/TState";
import { Button, Row, Space, TablePaginationConfig, Tooltip } from "antd";
import { FilterValue } from "antd/es/table/interface";
import SearchInput from "../../Utils/SearchInput";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import StateTable from "./StateTable";
import stateRepo from "../../API/LayoutApi/state.repo";
import StateForm from "./StateForm";

function StatePage() {
  const {
    search,
    setSearch,
    setPage,
    setIsActive,
    data,
    refetch,
    isLoading,
    isFetching,
  } = useStateList();

  const [formOpen, setFormOpen] = useState(false);
  const [editingState, setEditingState] = useState<TState>();

  function openForm(state?: TState) {
    setFormOpen(true);
    setEditingState(state);
  }

  function onChangeTable(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) {
    setPage(pagination.current as number);
    if (
      !filters.is_active ||
      (filters.is_active && filters.is_active.length === 2)
    ) {
      setIsActive("");
    } else if (filters.is_active && filters.is_active.length === 1) {
      setIsActive(String(filters.is_active[0]));
    }
  }

  return (
    <div>
      <Row align="top" justify="space-between" style={{ marginBottom: 16 }}>
        <Space>
          <SearchInput
            value={search}
            onDebounceChange={setSearch}
            placeholder="Search"
            style={{ width: 250 }}
          />
        </Space>
        <Space>
          <Button
            onClick={() => openForm()}
            type="primary"
            icon={<PlusOutlined />}
          >
            Add State
          </Button>
          <Tooltip title="Refresh">
            <Button
              onClick={() => refetch()}
              loading={isFetching}
              icon={<SyncOutlined />}
            />
          </Tooltip>
        </Space>
      </Row>

      <StateTable
        data={data}
        isLoading={isLoading || isFetching}
        onChange={onChangeTable}
        onEdit={openForm}
        deleteData={(id: string) => stateRepo.deleteState(id)}
        refetch={() => refetch()}
      />

      {formOpen && (
        <StateForm
          open={formOpen}
          setOpen={setFormOpen}
          id={editingState?.id}
          initialValues={editingState}
          onSubmit={() => refetch()}
        />
      )}
    </div>
  );
}

export default StatePage;
