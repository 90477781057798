import React from "react";
import {
  Button,
  Modal,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import moment from "moment";
import { TPagination } from "../../types/common/TPagination";
import { TDevice } from "../../types/Device/TDevice";
import { FilterValue } from "antd/es/table/interface";
import { QueryObserverResult } from "react-query";

const DeviceTable = ({
  data,
  isLoading,
  onChange,
  onEdit,
  deleteDevice,
  refetch,
}: {
  data?: TPagination<TDevice[]>;
  isLoading?: boolean;
  onChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ): void;
  onEdit(user: TDevice): void;
  deleteDevice(id: string): Promise<void>;
  refetch(): Promise<QueryObserverResult<TPagination<TDevice[]>, unknown>>;
}) => {
  return (
    <Table
      onChange={onChange}
      loading={isLoading}
      pagination={{
        total: data?.total,
      }}
      columns={[
        {
          title: "#",
          dataIndex: "index",
          key: "no",
        },
        {
          title: "app_version",
          dataIndex: "app_version",
          key: "app_version",
        },
        {
          title: "device_info",
          dataIndex: "device_info",
          key: "device_info",
        },
        {
          title: "fcm_token",
          dataIndex: "fcm_token",
          key: "fcm_token",
        },
        {
          title: "created_at",
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: "model",
          dataIndex: "model",
          key: "model",
        },
        {
          title: "Is Active",
          dataIndex: "is_active",
          key: "is_active",
          render: (tag: boolean) => (
            <Tag color={tag ? "geekblue" : "red"}>{tag ? "True" : "False"}</Tag>
          ),
          filters: [
            {
              text: "True",
              value: true,
            },
            {
              text: "False",
              value: false,
            },
          ],
        },
        {
          title: "Actions",
          dataIndex: "action",
          key: "action",
          render: ({ id }: { id: string }) => (
            <Space>
              <Tooltip title="Edit Device">
                <Button
                  onClick={() => {
                    if (data?.data) {
                      const device = data.data.find(
                        (device) => device.id === id
                      );
                      if (device) {
                        onEdit(device);
                      }
                    }
                  }}
                  icon={<EditOutlined />}
                />
              </Tooltip>

              <Tooltip title="Delete Device">
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    Modal.confirm({
                      title: "Device",
                      icon: <ExclamationCircleFilled />,
                      content: "Do you want to delete this device ?",
                      onOk: () => deleteDevice(id).then(() => refetch()),
                    })
                  }
                  danger
                />
              </Tooltip>
            </Space>
          ),
        },
      ]}
      dataSource={data?.data?.map((u: TDevice, i: number) => ({
        index: i + 1,
        app_version: u?.app_version,
        device_info: u?.device_info,
        fcm_token: u?.fcm_token,
        created_at: u.created_at
          ? moment(u.created_at).format("YYYY-MM-DD")
          : "",
        user_id: u?.user_id,
        model: u.model,
        is_active: u.is_active,
        action: { id: u.id },
        key: u.id,
      }))}
    />
  );
};

export default DeviceTable;
