import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import queryString from "query-string";

function useRouteQuery<T>(
  initValue: T,
  queryKey: string,
  convertCallback: (v: string | string[]) => T
): [T, (value: T) => void] {
  const [state, setState] = useState<T>(initValue);
  const [searchParams, setSearchParams] = useSearchParams();

  const cachedCb = useCallback(convertCallback, []);

  useEffect(() => {
    let searchValue = searchParams.get(queryKey);
    if (searchValue) {
      setState(cachedCb(searchValue));
    }
  }, [cachedCb, queryKey, searchParams]);

  function changeRouteQueryState(value: T) {
    setState(value);
    const routeQuery = queryString.parse(window.location.search) as any;

    value
      ? (routeQuery[queryKey] = String(value))
      : delete routeQuery[queryKey];

    setSearchParams(routeQuery);
  }

  return [state, changeRouteQueryState];
}

export default useRouteQuery;
