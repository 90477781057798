import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import BusinessSelect from "../Business/BusinessSelect";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { TProviderAccount } from "../../types/Provider/TProvider";
import ProviderAccountForm from "./ProviderAccountForm";
import { useMutation, useQuery } from "react-query";
import providerRepo from "../../API/LayoutApi/provider.repo";
import { TProviderForm } from "../../types/Provider/TProviderForm";
const { Title, Paragraph } = Typography;

function ProviderFormPage() {
  const routerNavigate = useNavigate();
  const { id } = useParams();

  const {
    isLoading: getLoading,
    data: provider,
    refetch,
  } = useQuery({
    queryKey: ["provider", id],
    queryFn: () => providerRepo.getProviderById(id as string),
    enabled: id !== "0",
  });

  const { isLoading: createLoading, mutateAsync: mutateCreate } = useMutation({
    mutationFn: (provider: TProviderForm) => providerRepo.addProvider(provider),
    onSuccess: () => {
      refetch();
      routerNavigate("/providers");
    },
    onError: (error: string) => {
      message.error(error);
    },
  });

  const { isLoading: updateLoading, mutateAsync: mutateUpdate } = useMutation({
    mutationFn: ({ id, provider }: { id: string; provider: TProviderForm }) =>
      providerRepo.editProvider(id, provider),
    onSuccess: () => {
      refetch();
      routerNavigate("/providers");
    },
    onError: (error: string) => {
      message.error(error);
    },
  });

  const [form] = Form.useForm();
  const [types] = useState([
    {
      id: "type-1",
      title: "Acceptance of electronic payments with billing",
      description:
        "Usually used in a full-featured online stores on one of the popular e-commerce CMS family",
    },
    {
      id: "type-2",
      title: "Payzap QR",
      description:
        "Acceptance of payments for payment by scanning the Payzap QR code on the customer's device by the cashier",
    },
  ]);

  function onFinishForm(values: TProviderForm) {
    if (id && id !== "0") {
      mutateUpdate({ id, provider: values });
    } else {
      mutateCreate(values);
    }
  }

  const [accountFormOpen, setAccountFormOpen] = useState(false);
  const [editingDetailIndex, setEditingDetailIndex] = useState<number>();
  const [account, setAccount] = useState<TProviderAccount[]>([]);

  function openDetailForm(index?: number) {
    setEditingDetailIndex(index);
    setAccountFormOpen(true);
  }

  function onSubmitDetailForm(detail: TProviderAccount) {
    editingDetailIndex
      ? setAccount(
          account.map((d, index) => (editingDetailIndex === index ? detail : d))
        )
      : setAccount([...account, detail]);
  }

  useEffect(() => {
    if (provider?.account) {
      setAccount(provider.account);
    }
  }, [provider]);

  return (
    <Spin spinning={getLoading}>
      <Title level={3}>Provider form</Title>
      {!getLoading && (
        <Form
          layout="vertical"
          form={form}
          initialValues={provider}
          onFinish={onFinishForm}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Business"
                name="business_id"
                rules={[{ required: true, message: "Please select business!" }]}
              >
                <BusinessSelect />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Type"
                name="type"
                rules={[
                  { required: true, message: "Please select provider type!" },
                ]}
              >
                <Radio.Group>
                  {types.map((type) => (
                    <Radio value={type.id} key={type.id}>
                      <Title level={5}>{type.title}</Title>
                      <Paragraph>{type.description}</Paragraph>
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Webhook" name="webhook">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Key" name="key">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Test key" name="test_key">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Latitude" name="lat">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Longitude" name="lng">
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Min amount" name="min_amount">
                <InputNumber addonBefore="$" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Max amount" name="max_amount">
                <InputNumber addonBefore="$" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Commission" name="commission">
                <InputNumber addonBefore="%" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="Commission from user"
                name="commission_from_user"
              >
                <InputNumber addonBefore="%" />
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginBottom: 16 }}>
              <Row justify="space-between" style={{ marginBottom: 16 }}>
                <Title level={5}>Payment details</Title>
                <Button
                  onClick={() => openDetailForm()}
                  icon={<PlusOutlined />}
                  type="primary"
                  ghost
                >
                  Add detail
                </Button>
              </Row>
              <Table
                columns={[
                  { dataIndex: "name", key: "name", title: "Name" },
                  { dataIndex: "title", key: "title", title: "Title" },
                  {
                    dataIndex: "validation",
                    key: "validation",
                    title: "Validation",
                  },
                  {
                    dataIndex: "actions",
                    key: "actions",
                    title: "Actions",
                    render: (_, record, index) => (
                      <Space>
                        <Tooltip title="Edit detail">
                          <Button
                            onClick={() => openDetailForm(index)}
                            icon={<EditOutlined />}
                          />
                        </Tooltip>

                        <Tooltip title="Delete detail">
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() =>
                              Modal.confirm({
                                title: "Provider",
                                icon: <ExclamationCircleFilled />,
                                content:
                                  "Do you want to delete this provider's detail ?",
                                onOk() {
                                  setAccount(
                                    account.filter(
                                      (item) => item.name !== record.name
                                    )
                                  );
                                },
                              })
                            }
                            danger
                          />
                        </Tooltip>
                      </Space>
                    ),
                  },
                ]}
                dataSource={account}
                pagination={false}
              />
            </Col>
            <Col>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={createLoading || updateLoading}
                >
                  Save
                </Button>
                <Link to="/providers">
                  <Button size="large">Back</Button>
                </Link>
              </Space>
            </Col>
          </Row>
          {accountFormOpen && (
            <ProviderAccountForm
              open={accountFormOpen}
              setOpen={setAccountFormOpen}
              onSubmit={onSubmitDetailForm}
              initialValues={
                editingDetailIndex ? account[editingDetailIndex] : undefined
              }
            />
          )}
        </Form>
      )}
    </Spin>
  );
}

export default ProviderFormPage;
