import {
  Button,
  Modal,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
} from "antd";
import {
  ClusterOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  RollbackOutlined,
} from "@ant-design/icons";
import moment from "moment/moment";
import { QueryObserverResult } from "react-query";
import { TPagination } from "../../types/common/TPagination";
import { FilterValue } from "antd/es/table/interface";
import { TProvider } from "../../types/Provider/TProvider";

const ProviderTable = ({
  data,
  onEdit,
  onSetState,
  onChange,
  isLoading,
  deleteData,
  refetch,
}: {
  data?: TPagination<TProvider[]>;
  onEdit?(provider: TProvider): void;
  onSetState?(id: string): void;
  onChange?(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ): void;
  isLoading?: boolean;
  deleteData?(id: string, isActive: boolean): Promise<void>;
  refetch?(): Promise<QueryObserverResult<TPagination<TProvider[]>, unknown>>;
}) => {
  return (
    <Table
      columns={[
        {
          title: "#",
          dataIndex: "index",
          key: "no",
        },
        {
          title: "name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "lat",
          dataIndex: "lat",
          key: "lat",
        },
        {
          title: "lng",
          dataIndex: "lng",
          key: "lng",
        },
        {
          title: "max amount",
          dataIndex: "max_amount",
          key: "max_amount",
        },
        {
          title: "min amount",
          dataIndex: "min_amount",
          key: "min_amount",
        },
        {
          title: "created at",
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: "Is Active",
          dataIndex: "is_active",
          key: "is_active",
          render: (tag: boolean) => (
            <Tag color={tag ? "geekblue" : "red"}>{tag ? "True" : "False"}</Tag>
          ),
          filters: [
            {
              text: "True",
              value: true,
            },
            {
              text: "False",
              value: false,
            },
          ],
        },
        {
          title: "Actions",
          dataIndex: "action",
          key: "action",
          render: (id, provider) => (
            <Space>
              <Tooltip title="Set state">
                <Button
                  onClick={() => onSetState && onSetState(id)}
                  icon={<ClusterOutlined />}
                />
              </Tooltip>
              <Tooltip title="Edit Provider">
                <Button
                  onClick={() => {
                    if (data?.data) {
                      const provider = data.data.find(
                        (provider) => provider.id === id
                      );
                      if (provider && onEdit) {
                        onEdit(provider);
                      }
                    }
                  }}
                  icon={<EditOutlined />}
                />
              </Tooltip>

              <Tooltip
                title={
                  provider.is_active ? "Delete provider" : "Rollback provider"
                }
              >
                <Button
                  icon={
                    provider.is_active ? (
                      <DeleteOutlined />
                    ) : (
                      <RollbackOutlined />
                    )
                  }
                  onClick={() =>
                    Modal.confirm({
                      title: "Provider",
                      icon: <ExclamationCircleFilled />,
                      content: "Do you want to delete this provider ?",
                      onOk: () =>
                        deleteData &&
                        deleteData(id, provider.is_active).then(() => {
                          refetch && refetch();
                        }),
                    })
                  }
                  danger
                />
              </Tooltip>
            </Space>
          ),
        },
      ]}
      dataSource={data?.data?.map((u: TProvider, i: number) => ({
        index: i + 1,
        ...u,
        created_at: u.created_at
          ? moment(u.created_at).format("YYYY-MM-DD")
          : "",
        action: u.id,
        key: u.id,
      }))}
      pagination={{
        total: data?.total,
      }}
      onChange={onChange}
      loading={isLoading}
    />
  );
};

export default ProviderTable;
