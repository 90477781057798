import useRouteQuery from "../common/useRouteQuery";
import { useQuery } from "react-query";
import userRepo from "../../API/LayoutApi/user.repo";

function useUserList({
  pageRouteQuery = "page",
  emailRouteQuery = "email",
  phoneRouteQuery = "phone",
  searchRouteQuery = "search",
  isActiveRouteQuery = "is_active",
} = {}) {
  const [page, setPage] = useRouteQuery(1, pageRouteQuery, (v) => Number(v));
  const [email, setEmail] = useRouteQuery("", emailRouteQuery, (v) =>
    String(v)
  );
  const [phone, setPhone] = useRouteQuery("", phoneRouteQuery, (v) =>
    String(v)
  );
  const [search, setSearch] = useRouteQuery("", searchRouteQuery, (v) =>
    String(v)
  );
  const [isActive, setIsActive] = useRouteQuery("", isActiveRouteQuery, (v) =>
    String(v)
  );

  const { isLoading, isFetching, data, refetch } = useQuery({
    queryKey: ["users", page, email, phone, search, isActive],
    queryFn: () =>
      userRepo.getUsers({
        page,
        perPage: 10,
        email: email ? email : undefined,
        phone: phone ? phone : undefined,
        search: search ? search : undefined,
        is_active: isActive ? JSON.parse(isActive) : undefined,
      }),
    refetchOnWindowFocus: false,
  });

  return {
    page,
    setPage,
    email,
    setEmail,
    phone,
    setPhone,
    search,
    setSearch,
    isActive,
    setIsActive,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

export default useUserList;
