import { AxiosResponse } from "axios";
import { TBusiness } from "../../types/Business/TBusiness";
import { TPagination } from "../../types/common/TPagination";
import instance from "../api";

export type TGetLocationsParams = {
  search?: string;
  is_active?: boolean;
  page: number;
  perPage: number;
};

const businessRepo = {
  baseUrl: "admin-business",

  getBusinesses(filterObject: TGetLocationsParams) {
    const params: TGetLocationsParams = { ...filterObject };

    if (!!filterObject.search) params.search = filterObject.search;
    if (!!filterObject.is_active) params.is_active = filterObject.is_active;

    return instance
      .get<TPagination<TBusiness[]>>(this.baseUrl, {
        params,
      })
      .then((response) => response.data);
  },

  addBusiness(name: string) {
    return instance
      .post<{ name: string }, AxiosResponse<TBusiness>>(this.baseUrl, { name })
      .then((res) => res.data);
  },

  editBusiness(id: string, name: string) {
    return instance
      .put<{ name: string }, AxiosResponse<TBusiness>>(
        `${this.baseUrl}/${id}`,
        { name }
      )
      .then(() => {});
  },

  deleteBusiness(id: string) {
    return instance.delete(`${this.baseUrl}/${id}`).then(() => {});
  },

  fileUpload(id: string, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return instance
      .post(`${this.baseUrl}/file-upload/${id}`, formData)
      .then(() => {});
  },

  deleteFile(id: string) {
    return instance.delete(`${this.baseUrl}/delete-file/${id}`);
  },
};

export default businessRepo;
