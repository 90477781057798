import { Button, Form, Modal, Row, Space, Spin, Tag, message } from "antd";
import { TProviderSetStateForm } from "../../types/Provider/TProviderSetStateForm";
import StateSelect from "../State/StateSelect";
import CategorySelect from "../Category/CategorySelect";
import { useMutation, useQuery } from "react-query";
import providerRepo from "../../API/LayoutApi/provider.repo";

function ProviderSetState({
  open,
  setOpen,
  providerId,
  onSubmit,
}: {
  open?: boolean;
  setOpen?(value: boolean): void;
  providerId?: string;
  onSubmit?(value: TProviderSetStateForm): void;
}) {
  const [form] = Form.useForm();

  const { isLoading, data, refetch } = useQuery({
    queryKey: ["provider-category-state", providerId],
    queryFn: () => {
      if (providerId) {
        return providerRepo.getCategoryState(providerId);
      }
    },
    enabled: !!providerId,
  });
  const { isLoading: formLoading, mutateAsync: mutateSetState } = useMutation({
    mutationFn: (formValues: TProviderSetStateForm) =>
      providerRepo.setStateToProvider(providerId as string, formValues),
    onSuccess() {
      refetch();
      form.resetFields();
      message.success("State setted to provider successfully!");
    },
    onError(error: string) {
      message.error(error);
    },
  });
  const { isLoading: deleteLoading, mutate: mutateDelete } = useMutation({
    mutationFn: (categoryId: string) =>
      providerRepo.deleteCategoryState(providerId as string, categoryId),
    onSuccess: () => {
      refetch();
      message.success("State deleted from provider successfully!");
    },
    onError: (error: string) => {
      message.error(error);
    },
  });
  function onFinishForm(values: TProviderSetStateForm) {
    mutateSetState(values);
    onSubmit && onSubmit(values);
  }
  return (
    <Modal
      open={open}
      onCancel={() => setOpen && setOpen(false)}
      title="Set state"
      footer={null}
      maskClosable={false}
    >
      <div style={{ margin: "16px 0" }}>
        <Spin spinning={isLoading || deleteLoading}>
          <Space wrap>
            {data &&
              data.map((categoryState) => (
                <Tag
                  key={categoryState.id}
                  color="blue"
                  closable
                  onClose={() => {
                    mutateDelete(categoryState.id);
                  }}
                >
                  {categoryState.name}{" "}
                  {categoryState.states.map((state, stateIndex) => (
                    <span key={state.id}>
                      {state.name +
                        (stateIndex < categoryState.states.length - 1
                          ? ", "
                          : "")}
                    </span>
                  ))}
                </Tag>
              ))}
          </Space>
        </Spin>
      </div>

      <Form form={form} onFinish={onFinishForm} layout="vertical">
        <Form.Item
          label="States"
          name="state_ids"
          rules={[{ required: true, message: "Please select states!" }]}
        >
          <StateSelect multiple />
        </Form.Item>
        <Form.Item
          label="Category"
          name="category_id"
          rules={[{ required: true, message: "Please select category!" }]}
        >
          <CategorySelect />
        </Form.Item>
        <Row justify="end">
          <Space>
            <Button onClick={() => setOpen && setOpen(false)}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={formLoading}>
              Save
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
}

export default ProviderSetState;
