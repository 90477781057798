import { useQuery } from "react-query";
import useRouteQuery from "../common/useRouteQuery";
import deviceRepo from "../../API/LayoutApi/device.repo";

function useDeviceList() {
  const [userId, setUserId] = useRouteQuery("", "user_id", (v) => String(v));
  const [page, setPage] = useRouteQuery(1, "page", (v) => Number(v));
  const [isActive, setIsActive] = useRouteQuery("", "is_active", (v) =>
    String(v)
  );

  const { isLoading, isFetching, data, refetch } = useQuery({
    queryKey: ["devices", page, userId, isActive],
    queryFn: () =>
      deviceRepo.getDevices({
        page,
        perPage: 10,
        user_id: userId ? userId : undefined,
        is_active: isActive ? JSON.parse(isActive) : undefined,
      }),
    refetchOnWindowFocus: false,
  });

  return {
    userId,
    setUserId,
    page,
    setPage,
    isActive,
    setIsActive,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

export default useDeviceList;
