import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  message,
} from "antd";
import { useMutation } from "react-query";
import { TBusinessUser } from "../../types/BusinessUser/TBusinessUser";
import { TBusinessUserForm } from "../../types/BusinessUser/TBusinessUserForm";
import businessUserRepo from "../../API/LayoutApi/businessUser.repo";
import BusinessSelect from "../Business/BusinessSelect";

function BusinessUserForm({
  id,
  open,
  initialValues,
  setOpen,
  onSubmit,
}: {
  id?: string;
  open?: boolean;
  initialValues?: TBusinessUser;
  setOpen?: (value: boolean) => void;
  onSubmit?: (formValues: TBusinessUserForm) => void;
}) {
  const { isLoading: loadingCreate, mutateAsync: mutateCreate } = useMutation({
    mutationFn: (formValues: TBusinessUserForm) =>
      businessUserRepo.addBusinessUser(formValues),
    onError: (error: string) => {
      message.error(error);
    },
  });
  const { isLoading: loadingUpdate, mutateAsync: mutateUpdate } = useMutation({
    mutationFn: ({
      id,
      formValues,
    }: {
      id: string;
      formValues: TBusinessUserForm;
    }) => businessUserRepo.businessUserEdit(id, formValues),
    onError: (error: string) => {
      message.error(error);
    },
  });

  async function onFinishForm(formValues: any) {
    try {
      !id
        ? await mutateCreate(formValues)
        : await mutateUpdate({ id, formValues });
      onSubmit && onSubmit(formValues);
      setOpen && setOpen(false);
    } catch (err) {}
  }

  return (
    <Modal
      open={open}
      onCancel={() => setOpen && setOpen(false)}
      title="Edit Location"
      footer={null}
      maskClosable={false}
    >
      <Spin spinning={loadingCreate || loadingUpdate}>
        <Form
          layout="vertical"
          initialValues={initialValues}
          onFinish={onFinishForm}
        >
          <Form.Item
            label="Business"
            name="business_id"
            rules={[
              { required: true, message: "Please select your business!" },
            ]}
          >
            <BusinessSelect />
          </Form.Item>
          {/* <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item> */}
          <Form.Item
            label="Password"
            name="password"
            rules={
              !id
                ? [{ required: true, message: "Please input your password!" }]
                : []
            }
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="First name"
            name="first_name"
            rules={[
              { required: true, message: "Please input your first name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Second name"
            name="second_name"
            rules={[
              { required: true, message: "Please input your second name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[{ required: true, message: "Please input your role!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="is_active">
            <Radio.Group>
              <Radio value={true}>Active</Radio>
              <Radio value={false}>Not active</Radio>
            </Radio.Group>
          </Form.Item>
          <Row justify="end">
            <Space>
              <Button onClick={() => setOpen && setOpen(false)}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default BusinessUserForm;
