import { CSSProperties, useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import { Select } from "antd";
import businessRepo from "../../API/LayoutApi/business.repo";

type TBusinessValue = undefined | string | string[];

function BusinessSelect({
  value,
  onChange,
  multiple = false,
  style,
}: {
  value?: TBusinessValue;
  onChange?(value: TBusinessValue): void;
  multiple?: boolean;
  style?: CSSProperties;
} = {}) {
  const [search, setSearch] = useState("");

  const { isFetching, data } = useQuery({
    queryKey: ["businesses", search],
    queryFn: () => businessRepo.getBusinesses({ page: 1, perPage: 10, search }),
    refetchOnWindowFocus: false,
  });

  const [searchValue, setSearchValue] = useState("");

  const options = useMemo(() => {
    if (data?.data) {
      return data.data.map((business) => ({
        label: business.name,
        value: business.id,
      }));
    }
    return [];
  }, [data]);

  const debounceChangeSearch = useCallback(debounce(setSearch, 500), []);

  function onSearchFromSelect(val: string) {
    setSearchValue(val);
    debounceChangeSearch(val);
  }

  const filterOption = useCallback(
    (input: string, option?: { label: string; value: string }) =>
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase()),
    []
  );

  return (
    <Select
      showSearch
      placeholder="Search business"
      optionFilterProp="children"
      searchValue={searchValue}
      value={value}
      onChange={onChange}
      onSearch={onSearchFromSelect}
      options={options}
      filterOption={filterOption}
      loading={isFetching}
      allowClear
      mode={multiple ? "multiple" : undefined}
      style={style}
    />
  );
}

export default BusinessSelect;
