import { THistoryForm } from "./../../types/History/THistoryForm";
import { THistory } from "../../types/History/THistory";
import { TPagination } from "../../types/common/TPagination";
import instance from "../api";

export type TGetHistoriesParams = {
  user_id?: string;
  search?: string;
  is_active?: boolean;
  page: number;
  perPage: number;
};

const historyRepo = {
  baseUrl: "admin-history-payment",
  getHistories(filterObject: TGetHistoriesParams) {
    const params: TGetHistoriesParams = { ...filterObject };

    if (!!filterObject.user_id) params.user_id = filterObject.user_id;
    if (!!filterObject.search) params.search = filterObject.search;
    if (!!filterObject.is_active) params.is_active = filterObject.is_active;

    return instance
      .get<TPagination<THistory[]>>(this.baseUrl, {
        params,
      })
      .then((response) => response.data);
  },

  addHistory(formValues: THistoryForm) {
    return instance.post(this.baseUrl, formValues).then(() => {});
  },

  historyEdit(id: string, formValues: THistoryForm) {
    return instance.put(`${this.baseUrl}/${id}`, formValues).then(() => {});
  },

  historyDelete(id: string) {
    return instance.delete(`${this.baseUrl}/${id}`).then(() => {});
  },
};

export default historyRepo;
