import { Button, Form, Input, Modal, Row, Space, Spin, message } from "antd";
import { useMutation } from "react-query";
import { TAddLocation } from "../../types/Location/TAddLocation";
import locationRepo from "../../API/LayoutApi/location.repo";
import UserAutoComplete from "../User/UserAutoComplete";

function LocationAddForm({
  open,
  setOpen,
  onSubmit,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSubmit: (formValues: TAddLocation) => void;
}) {
  const [form] = Form.useForm();
  const { isLoading, mutateAsync: mutateLocationCreate } = useMutation({
    mutationFn: (addObject: TAddLocation) =>
      locationRepo.addLocation(addObject),
    onSuccess: () => {
      message.success("Location created successfully!");
      setOpen(false);
      form.resetFields();
    },
    onError: (error: string) => {
      message.error(error);
    },
  });

  function onFinishForm(values: TAddLocation) {
    mutateLocationCreate(values).then(() => {
      onSubmit(values);
    });
  }

  function onCancel() {
    setOpen(false);
    form.resetFields();
  }

  return (
    <Modal
      open={open}
      title="Add Location"
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <Form layout="vertical" onFinish={onFinishForm} form={form}>
          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true, message: "Please input your country!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: "Please input your state!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "Please input your city!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: "Please input your address!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Zip code"
            name="zip_code"
            rules={[{ required: true, message: "Please input your zip_code!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="User Id"
            name="user_id"
            rules={[{ required: true, message: "Please select your user!" }]}
          >
            <UserAutoComplete />
          </Form.Item>

          <Row justify="end">
            <Space>
              <Button onClick={() => onCancel()}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default LocationAddForm;
