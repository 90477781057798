import {
  Button,
  Modal,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
} from "antd";
import {
  ExclamationCircleFilled,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { TPagination } from "../../types/common/TPagination";
import { TState } from "../../types/State/TState";
import { FilterValue } from "antd/es/table/interface";
import { QueryObserverResult } from "react-query";

const StateTable = ({
  data,
  onEdit,
  onChange,
  isLoading,
  deleteData,
  refetch,
}: {
  data?: TPagination<TState[]>;
  onEdit?(state: TState): void;
  onChange?(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ): void;
  isLoading?: boolean;
  deleteData?(id: string): Promise<void>;
  refetch?(): Promise<QueryObserverResult<TPagination<TState[]>, unknown>>;
}) => {
  return (
    <Table
      loading={isLoading}
      onChange={onChange}
      pagination={{
        total: data?.total,
      }}
      dataSource={data?.data?.map((u, i) => ({
        no: i + 1,
        name: u?.name,
        is_active: u.is_active,
        action: { id: u.id },
        key: u.id,
      }))}
      columns={[
        {
          title: "#",
          dataIndex: "no",
          key: "no",
        },
        {
          title: "name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Is Active",
          dataIndex: "is_active",
          key: "is_active",
          render: (tag: boolean) => (
            <Tag color={tag ? "geekblue" : "red"}>{tag ? "True" : "False"}</Tag>
          ),
          filters: [
            {
              text: "True",
              value: true,
            },
            {
              text: "False",
              value: false,
            },
          ],
        },
        {
          title: "Actions",
          dataIndex: "action",
          key: "action",
          render: ({ id }: { id: string }) => (
            <Space>
              <Tooltip title="Edit state">
                <Button
                  onClick={() => {
                    if (data?.data) {
                      const state = data.data.find((state) => state.id === id);
                      if (state) {
                        onEdit && onEdit(state);
                      }
                    }
                  }}
                  icon={<EditOutlined />}
                />
              </Tooltip>

              <Tooltip title="Delete state">
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    Modal.confirm({
                      title: "State",
                      icon: <ExclamationCircleFilled />,
                      content: "Do you want to delete this state?",
                      onOk: () =>
                        deleteData &&
                        deleteData(id).then(() => {
                          refetch && refetch();
                        }),
                    })
                  }
                  danger
                />
              </Tooltip>
            </Space>
          ),
        },
      ]}
    />
  );
};

export default StateTable;
