import {
  Button,
  Modal,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
} from "antd";
import { TPagination } from "../../types/common/TPagination";
import { FilterValue } from "antd/es/table/interface";
import { QueryObserverResult } from "react-query";
import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import moment from "moment";
import { TNotification } from "../../types/Notification/TNotification";

function NotificationTable({
  data,
  onChange,
  isLoading,
  deleteData,
  refetch,
}: {
  data?: TPagination<TNotification[]>;
  onChange?(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ): void;
  isLoading?: boolean;
  deleteData?(id: string): Promise<void>;
  refetch?(): Promise<
    QueryObserverResult<TPagination<TNotification[]>, unknown>
  >;
}) {
  return (
    <Table
      onChange={onChange}
      loading={isLoading}
      pagination={{
        total: data?.total,
      }}
      columns={[
        {
          title: "#",
          dataIndex: "index",
          key: "no",
        },
        {
          title: "title",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "text",
          dataIndex: "text",
          key: "text",
        },
        {
          title: "user id",
          dataIndex: "user_id",
          key: "user_id",
        },
        {
          title: "date",
          dataIndex: "date",
          key: "date",
        },
        {
          title: "created_at",
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: "is read",
          dataIndex: "is_read",
          key: "is_read",
          render: (tag: boolean) => (
            <Tag color={tag ? "geekblue" : "red"}>{tag ? "True" : "False"}</Tag>
          ),
          filters: [
            {
              text: "True",
              value: true,
            },
            {
              text: "False",
              value: false,
            },
          ],
        },
        {
          title: "Actions",
          dataIndex: "action",
          key: "action",
          render: ({ id }: { id: string }) => (
            <Tooltip title="Delete Notification">
              <Button
                icon={<DeleteOutlined />}
                onClick={() =>
                  Modal.confirm({
                    title: "Notification",
                    icon: <ExclamationCircleFilled />,
                    content: "Do you want to delete this notification ?",
                    onOk: () =>
                      deleteData &&
                      deleteData(id).then(() => refetch && refetch()),
                  })
                }
                danger
              />
            </Tooltip>
          ),
        },
      ]}
      dataSource={data?.data?.map((u: TNotification, i: number) => ({
        index: i + 1,
        ...u,
        date: u.date ? moment(u.date).format("YYYY-MM-DD") : "",
        created_at: u.created_at
          ? moment(u.created_at).format("YYYY-MM-DD")
          : "",
        action: { id: u.id },
        key: u.id,
      }))}
    />
  );
}

export default NotificationTable;
