import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  message,
} from "antd";
import { useMutation } from "react-query";
import stateRepo from "../../API/LayoutApi/state.repo";
import { TState } from "../../types/State/TState";

export type TStateForm = { name: string; is_active: boolean };

function StateForm({
  id,
  initialValues,
  open,
  setOpen,
  onSubmit,
}: {
  id?: string;
  initialValues?: TStateForm;
  open?: boolean;
  setOpen?: (value: boolean) => void;
  onSubmit?: (formValues: TStateForm) => void;
}) {
  const { isLoading: loadingCreate, mutateAsync: mutateCreate } = useMutation({
    mutationFn: (name: string) => stateRepo.addState(name),
    onError: (error: string) => {
      message.error(error);
    },
  });
  const { isLoading: loadingUpdate, mutateAsync: mutateUpdate } = useMutation({
    mutationFn: ({
      id,
      formValues,
    }: {
      id: string;
      formValues: Omit<TState, "id">;
    }) => stateRepo.editState(id, formValues),
    onError: (error: string) => {
      message.error(error);
    },
  });

  async function onFinishForm(formValues: TStateForm) {
    try {
      !id
        ? await mutateCreate(formValues.name)
        : await mutateUpdate({ id, formValues });
      onSubmit && onSubmit(formValues);
      setOpen && setOpen(false);
    } catch (err) {}
  }

  return (
    <Modal
      open={open}
      onCancel={() => setOpen && setOpen(false)}
      title={id ? "Edit State" : "Add State"}
      footer={null}
      maskClosable={false}
    >
      <Spin spinning={loadingCreate || loadingUpdate}>
        <Form
          layout="vertical"
          onFinish={onFinishForm}
          initialValues={initialValues}
        >
          <Form.Item
            label="State name"
            name="name"
            rules={[
              { required: true, message: "Please input your state name!" },
            ]}
          >
            <Input />
          </Form.Item>
          {id && (
            <Form.Item label="Status" name="is_active">
              <Radio.Group>
                <Radio value={true}>Active</Radio>
                <Radio value={false}>Not active</Radio>
              </Radio.Group>
            </Form.Item>
          )}

          <Row justify="end">
            <Space>
              <Button onClick={() => setOpen && setOpen(false)}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default StateForm;
