import { TDevice } from "../../types/Device/TDevice";
import { TPagination } from "../../types/common/TPagination";
import instance from "../api";

export type TGetDevicesParams = {
  user_id?: string;
  page: number;
  perPage: number;
  is_active: boolean;
};

const deviceRepo = {
  baseUrl: "admin/devices",

  getDevices(filterObject: TGetDevicesParams) {
    const params: TGetDevicesParams = { ...filterObject };

    if (!!filterObject.user_id) params.user_id = filterObject.user_id;
    if (!!filterObject.is_active) params.is_active = filterObject.is_active;

    return instance
      .get<TPagination<TDevice[]>>(this.baseUrl, {
        params,
      })
      .then((response) => response.data);
  },

  deviceEdit(id: string, deviceEditObject: Omit<TDevice, "id">) {
    return instance
      .put(`${this.baseUrl}/${id}`, deviceEditObject)
      .then(() => {});
  },

  deleteDevice(id: string) {
    return instance.delete(`${this.baseUrl}/${id}`).then(() => {});
  },
};

export default deviceRepo;
