import { Button, Row, Space, TablePaginationConfig, Tooltip } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { FilterValue } from "antd/es/table/interface";
import { useState } from "react";
import UserAutoComplete from "../User/UserAutoComplete";
import useHistoryList from "../../Hooks/History/useHistoryList";
import SearchInput from "../../Utils/SearchInput";
import HistoryTable from "./HistoryTable";
import historyRepo from "../../API/LayoutApi/history.repo";
import { THistory } from "../../types/History/THistory";
import HistoryForm from "./HistoryForm";

function HistoryPage() {
  const {
    search,
    setSearch,
    userId,
    setUserId,
    setPage,
    setIsActive,
    isLoading,
    isFetching,
    data,
    refetch,
  } = useHistoryList();

  function onChangeTable(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) {
    setPage(pagination.current as number);
    if (
      !filters.is_active ||
      (filters.is_active && filters.is_active.length === 2)
    ) {
      setIsActive("");
    } else if (filters.is_active && filters.is_active.length === 1) {
      setIsActive(String(filters.is_active[0]));
    }
  }

  const [formOpen, setFormOpen] = useState(false);
  const [editHistory, setEditHistory] = useState<THistory>();
  function openForm(history?: THistory) {
    setEditHistory(history);
    setFormOpen(true);
  }

  return (
    <div>
      <Row align="top" justify="space-between" style={{ marginBottom: 16 }}>
        <Space>
          <UserAutoComplete value={userId} onChange={setUserId} />
          <SearchInput
            value={search}
            onDebounceChange={setSearch}
            placeholder="Search"
          />
        </Space>
        <Space>
          <Tooltip title="Refresh">
            <Button
              onClick={() => refetch()}
              loading={isFetching}
              icon={<SyncOutlined />}
            />
          </Tooltip>
        </Space>
      </Row>
      <HistoryTable
        data={data}
        isLoading={isLoading}
        onChange={onChangeTable}
        onEdit={openForm}
        deleteData={(id: string) => historyRepo.historyDelete(id)}
        refetch={refetch}
      />
      {formOpen && (
        <HistoryForm
          open={formOpen}
          initialValues={editHistory}
          id={editHistory?.id}
          setOpen={setFormOpen}
          onSubmit={() => refetch()}
        />
      )}
    </div>
  );
}

export default HistoryPage;
