import { useState } from "react";
import useCardList from "../../Hooks/Card/useCardList";
import { TCard } from "../../types/Card/TCard";
import {
  Button,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  TablePaginationConfig,
  Tooltip,
} from "antd";
import { FilterValue } from "antd/es/table/interface";
import UserAutoComplete from "../User/UserAutoComplete";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import locationRepo from "../../API/LayoutApi/location.repo";
import CardTable from "./CardTable";
import CardAddForm from "./CardAddForm";

function CardPage() {
  const {
    page,
    setPage,
    userId,
    setUserId,
    type,
    setType,
    isActive,
    setIsActive,
    isLoading,
    isFetching,
    data,
    refetch,
  } = useCardList();

  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editCard, setEditCard] = useState<TCard>();

  function onChangeType(value: "bank" | "card") {
    setType(type !== value ? value : "");
  }

  function onChangeTable(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) {
    setPage(pagination.current as number);
    if (
      !filters.is_active ||
      (filters.is_active && filters.is_active.length === 2)
    ) {
      setIsActive("");
    } else if (filters.is_active && filters.is_active.length === 1) {
      setIsActive(String(filters.is_active[0]));
    }
  }

  function openEditForm(card: TCard) {
    setEditCard(card);
    setEditFormOpen(true);
  }

  return (
    <div>
      <Row align="top" justify="space-between" style={{ marginBottom: 16 }}>
        <Space>
          <UserAutoComplete value={userId} onChange={setUserId} />
          <Button
            onClick={() => onChangeType("bank")}
            type={type === "bank" ? "primary" : "default"}
          >
            Bank
          </Button>
          <Button
            onClick={() => onChangeType("card")}
            type={type === "card" ? "primary" : "default"}
          >
            Card
          </Button>
        </Space>
        <Space>
          {/* <Button
            onClick={() => setAddFormOpen(true)}
            type="primary"
            icon={<PlusOutlined />}
          >
            Add Card
          </Button> */}
          <Tooltip title="Refresh">
            <Button
              onClick={() => refetch()}
              loading={isFetching}
              icon={<SyncOutlined />}
            />
          </Tooltip>
        </Space>
      </Row>
      <CardTable
        data={data}
        isLoading={isLoading || isFetching}
        onEdit={openEditForm}
        onChange={onChangeTable}
        deleteData={(id: string) => locationRepo.locationDelete(id)}
        refetch={() => refetch()}
      />
      {/* <CardAddForm
        open={addFormOpen}
        setOpen={setAddFormOpen}
        onSubmit={() => refetch()}
      /> */}
    </div>
  );
}

export default CardPage;
