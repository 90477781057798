import { TProviderSetStateForm } from "./../../types/Provider/TProviderSetStateForm";
import { TProviderForm } from "./../../types/Provider/TProviderForm";
import { TProvider } from "../../types/Provider/TProvider";
import { TPagination } from "../../types/common/TPagination";
import instance from "../api";
import { TProviderCategoryState } from "../../types/Provider/TProviderCategoryState";

type TGetProvidersParams = {
  search?: string;
  business_id?: string;
  status?: string;
  page: number;
  perPage: number;
  is_active?: boolean;
  category_ids?: string[];
};

const providerRepo = {
  baseUrl: "admin/providers",

  getProviders(filterObject: TGetProvidersParams) {
    const params: TGetProvidersParams = { ...filterObject };

    if (!!filterObject.search) params.search = filterObject.search;
    if (!!filterObject.business_id)
      params.business_id = filterObject.business_id;
    if (!!filterObject.status) params.status = filterObject.status;
    if (!!filterObject.is_active) params.is_active = filterObject.is_active;
    if (!!filterObject.category_ids)
      params.category_ids = filterObject.category_ids;

    return instance
      .get<TPagination<TProvider[]>>(this.baseUrl, {
        params,
      })
      .then((response) => response.data);
  },

  getProviderById(id: string) {
    return instance
      .get<TProvider>(`${this.baseUrl}/${id}`)
      .then((res) => res.data);
  },

  addProvider(formValues: TProviderForm) {
    return instance.post(this.baseUrl, formValues).then(() => {});
  },

  editProvider(id: string, formValues: TProviderForm) {
    return instance.put(`${this.baseUrl}/${id}`, formValues).then(() => {});
  },

  deleteProvider(id: string, is_active: boolean) {
    return instance
      .delete(`${this.baseUrl}/${id}`, { params: { is_active: !is_active } })
      .then(() => {});
  },

  getCategoryState(id: string) {
    return instance
      .get<TProviderCategoryState[]>(`${this.baseUrl}/category-state/${id}`)
      .then((res) => res.data);
  },

  setStateToProvider(id: string, formValues: TProviderSetStateForm) {
    return instance.post(`${this.baseUrl}/category-state/${id}`, formValues);
  },

  deleteCategoryState(id: string, category_id: string) {
    return instance.delete(`admin/remove-category/${id}`, {
      params: { category_id },
    });
  },
};

export default providerRepo;
