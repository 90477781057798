import { useMutation } from "react-query";
import { TLocation } from "../../types/Location/TLocation";
import locationRepo from "../../API/LayoutApi/location.repo";
import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  message,
} from "antd";
import UserAutoComplete from "../User/UserAutoComplete";

function LocationEditForm({
  initialValue,
  open,
  setOpen,
  onSubmit,
}: {
  initialValue: TLocation;
  open: boolean;
  setOpen: (value: boolean) => void;
  onSubmit: (userEditObject: TLocation) => void;
}) {
  const { isLoading, mutateAsync: mutateLocationEdit } = useMutation({
    mutationFn: ({
      id,
      locationEditObject,
    }: {
      id: string;
      locationEditObject: Omit<TLocation, "id">;
    }) => locationRepo.locationEdit(id, locationEditObject),
    onSuccess: () => {
      message.success("Location edited successfully!");
      setOpen(false);
    },
    onError: (error: string) => {
      message.error(error);
    },
  });

  function onFinishForm(values: Omit<TLocation, "id">) {
    mutateLocationEdit({
      id: initialValue.id,
      locationEditObject: values,
    }).then(() => {
      onSubmit({ id: initialValue.id, ...values });
    });
  }

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title="Edit Location"
      footer={null}
      maskClosable={false}
    >
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
          onFinish={onFinishForm}
          initialValues={initialValue}
        >
          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true, message: "Please input your country!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: "Please input your state!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "Please input your city!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: "Please input your address!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Zip code"
            name="zip_code"
            rules={[{ required: true, message: "Please input your zip_code!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="User Id"
            name="user_id"
            rules={[{ required: true, message: "Please select your user!" }]}
          >
            <UserAutoComplete />
          </Form.Item>
          <Form.Item label="Status" name="is_active">
            <Radio.Group>
              <Radio value={true}>Active</Radio>
              <Radio value={false}>Not active</Radio>
            </Radio.Group>
          </Form.Item>

          <Row justify="end">
            <Space>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default LocationEditForm;
