import { Button, Card, Form, Input, Modal, Row, Space } from "antd";
import { TProviderAccount } from "../../types/Provider/TProvider";
import { DeleteOutlined } from "@ant-design/icons";

function ProviderAccountForm({
  open,
  setOpen,
  initialValues,
  onSubmit,
}: {
  open?: boolean;
  setOpen?(value: boolean): void;
  initialValues?: TProviderAccount;
  onSubmit?(value: TProviderAccount): void;
}) {
  const [form] = Form.useForm();

  function onCancel() {
    setOpen && setOpen(false);
    form.resetFields();
  }

  function onFinishForm(values: TProviderAccount) {
    setOpen && setOpen(false);
    onSubmit && onSubmit(values);
  }

  return (
    <Modal
      open={open}
      onCancel={() => onCancel()}
      title="Detail form"
      footer={null}
      maskClosable={false}
    >
      <Form
        layout="vertical"
        initialValues={initialValues}
        form={form}
        onFinish={onFinishForm}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input title" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Error text" name="error">
          <Input />
        </Form.Item>
        <Form.Item label="Validation regex" name="validation">
          <Input placeholder="[0-9]+[a-z]" />
        </Form.Item>
        <Form.List name="options">
          {(fields, { add, remove }) => (
            <>
              <Card
                title="Options"
                extra={<Button onClick={() => add()}>Add option</Button>}
                style={{ marginBottom: 16 }}
              >
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "title"]}
                      rules={[{ required: true, message: "Missing title" }]}
                    >
                      <Input placeholder="Title" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "value"]}
                      rules={[{ required: true, message: "Missing value" }]}
                    >
                      <Input placeholder="Value" />
                    </Form.Item>
                    <Button
                      type="text"
                      icon={<DeleteOutlined onClick={() => remove(name)} />}
                    />
                  </Space>
                ))}
              </Card>
            </>
          )}
        </Form.List>
        <Row justify="end">
          <Space>
            <Button onClick={() => onCancel()}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
}

export default ProviderAccountForm;
