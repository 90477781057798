import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Upload,
  message,
} from "antd";
import { useMutation } from "react-query";
import businessRepo from "../../API/LayoutApi/business.repo";

export type TBusinessForm = { name: string; file?: File };

function BusinessForm({
  id,
  name,
  filePath,
  open,
  setOpen,
  onSubmit,
}: {
  id?: string;
  name?: string;
  filePath?: string;
  open?: boolean;
  setOpen?: (value: boolean) => void;
  onSubmit?: (formValues: TBusinessForm) => void;
}) {
  const { isLoading: loadingCreate, mutateAsync: mutateCreate } = useMutation({
    mutationFn: (name: string) => businessRepo.addBusiness(name),
    onError: (error: string) => {
      message.error(error);
    },
  });
  const { isLoading: loadingUpdate, mutateAsync: mutateUpdate } = useMutation({
    mutationFn: ({ id, name }: { id: string; name: string }) =>
      businessRepo.editBusiness(id, name),
    onError: (error: string) => {
      message.error(error);
    },
  });
  const { isLoading: loadingUpload, mutate: mutateUpload } = useMutation({
    mutationFn: ({ id, file }: { id: string; file: File }) =>
      businessRepo.fileUpload(id, file),
    onError: (error: string) => {
      message.error(error);
    },
  });

  function normFile(e: any) {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  }

  async function onFinishForm(values: any) {
    const formValues: TBusinessForm = {
      name: values.name,
    };
    if (values.file) {
      formValues.file = values.file[0].originFileObj;
    }
    try {
      const mutatedData = !id
        ? await mutateCreate(formValues.name)
        : await mutateUpdate({ id, name: formValues.name });
      if (formValues.file) {
        const uploadData: { id: string; file: File } = {
          id: "",
          file: formValues.file,
        };
        if (mutatedData) {
          uploadData.id = mutatedData.id;
        } else if (id) {
          uploadData.id = id;
        }
        mutateUpload(uploadData);
      }
      onSubmit && onSubmit(formValues);
      setOpen && setOpen(false);
    } catch (err) {}
  }

  return (
    <Modal
      open={open}
      onCancel={() => setOpen && setOpen(false)}
      title={id ? "Edit Business" : "Add Business"}
      footer={null}
      maskClosable={false}
    >
      <Spin spinning={loadingCreate || loadingUpdate || loadingUpload}>
        <Form
          layout="vertical"
          onFinish={onFinishForm}
          initialValues={{ name }}
        >
          <Form.Item
            label="Business name"
            name="name"
            rules={[
              { required: true, message: "Please input your business name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Business logo"
            name="file"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              listType="picture-card"
              name="file"
              maxCount={1}
              beforeUpload={() => false}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </Form.Item>
          <Row justify="end">
            <Space>
              <Button onClick={() => setOpen && setOpen(false)}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default BusinessForm;
