import {
  Button,
  Modal,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
} from "antd";
import { TLocation } from "../../types/Location/TLocation";
import { TPagination } from "../../types/common/TPagination";
import { FilterValue } from "antd/es/table/interface";
import { QueryObserverResult } from "react-query";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import moment from "moment";

function LocationTable({
  data,
  onEdit,
  onChange,
  isLoading,
  deleteData,
  refetch,
}: {
  data?: TPagination<TLocation[]>;
  onEdit(editingData: TLocation): void;
  onChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ): void;
  isLoading?: boolean;
  deleteData(id: string): Promise<void>;
  refetch(): Promise<QueryObserverResult<TPagination<TLocation[]>, unknown>>;
}) {
  return (
    <Table
      onChange={onChange}
      loading={isLoading}
      pagination={{
        total: data?.total,
      }}
      columns={[
        {
          title: "#",
          dataIndex: "index",
          key: "no",
        },
        {
          title: "address",
          dataIndex: "address",
          key: "address",
        },
        {
          title: "city",
          dataIndex: "city",
          key: "city",
        },
        {
          title: "zip_code",
          dataIndex: "zip_code",
          key: "zip_code",
        },
        {
          title: "created_at",
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: "state",
          dataIndex: "state",
          key: "state",
        },
        {
          title: "country",
          dataIndex: "country",
          key: "country",
        },
        {
          title: "Is Active",
          dataIndex: "is_active",
          key: "is_active",
          render: (tag: boolean) => (
            <Tag color={tag ? "geekblue" : "red"}>{tag ? "True" : "False"}</Tag>
          ),
          filters: [
            {
              text: "True",
              value: true,
            },
            {
              text: "False",
              value: false,
            },
          ],
        },
        {
          title: "Actions",
          dataIndex: "action",
          key: "action",
          render: ({ id }: { id: string }) => (
            <Space>
              <Tooltip title="Edit Location">
                <Button
                  onClick={() => {
                    if (data?.data) {
                      const device = data.data.find(
                        (device) => device.id === id
                      );
                      if (device) {
                        onEdit(device);
                      }
                    }
                  }}
                  icon={<EditOutlined />}
                />
              </Tooltip>

              <Tooltip title="Delete Location">
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    Modal.confirm({
                      title: "Location",
                      icon: <ExclamationCircleFilled />,
                      content: "Do you want to delete this location ?",
                      onOk: () => deleteData(id).then(() => refetch()),
                    })
                  }
                  danger
                />
              </Tooltip>
            </Space>
          ),
        },
      ]}
      dataSource={data?.data?.map((u: TLocation, i: number) => ({
        index: i + 1,
        address: u.address,
        city: u.city,
        zip_code: u.zip_code,
        state: u.state,
        country: u.country,
        is_active: u.is_active,
        created_at: u.created_at
          ? moment(u.created_at).format("YYYY-MM-DD")
          : "",
        action: { id: u.id },
        key: u.id,
      }))}
    />
  );
}

export default LocationTable;
