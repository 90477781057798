import { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import { Select } from "antd";
import stateRepo from "../../API/LayoutApi/state.repo";

type TStateValue = string | string[];

function StateSelect({
  value,
  onChange,
  multiple = false,
}: {
  value?: TStateValue;
  onChange?(value: TStateValue): void;
  multiple?: boolean;
} = {}) {
  const [search, setSearch] = useState("");

  const { isFetching, data } = useQuery({
    queryKey: ["states", search],
    queryFn: () => stateRepo.getStates({ page: 1, perPage: 10, search }),
    refetchOnWindowFocus: false,
  });

  const [searchValue, setSearchValue] = useState("");

  const options = useMemo(() => {
    if (data?.data) {
      return data.data.map((state) => ({
        label: state.name,
        value: state.id,
      }));
    }
    return [];
  }, [data]);

  const debounceChangeSearch = useCallback(debounce(setSearch, 500), []);

  function onSearchFromSelect(val: string) {
    setSearchValue(val);
    debounceChangeSearch(val);
  }

  const filterOption = useCallback(
    (input: string, option?: { label: string; value: string }) =>
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase()),
    []
  );

  return (
    <Select
      showSearch
      placeholder="Search state"
      optionFilterProp="children"
      searchValue={searchValue}
      value={value}
      onChange={onChange}
      onSearch={onSearchFromSelect}
      options={options}
      filterOption={filterOption}
      loading={isFetching}
      allowClear
      mode={multiple ? "multiple" : undefined}
    />
  );
}

export default StateSelect;
