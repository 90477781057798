import useRouteQuery from "../common/useRouteQuery";
import { useQuery } from "react-query";
import cardRepo from "../../API/LayoutApi/card.repo";

function useCardList({
  pageRouteQuery = "page",
  userIdRouteQuery = "user_id",
  typeRouteQuery = "type",
  isActiveRouteQuery = "is_active",
} = {}) {
  const [page, setPage] = useRouteQuery(1, pageRouteQuery, (v) => Number(v));
  const [userId, setUserId] = useRouteQuery("", userIdRouteQuery, (v) =>
    String(v)
  );
  const [type, setType] = useRouteQuery("", typeRouteQuery, (v) => String(v));
  const [isActive, setIsActive] = useRouteQuery("", isActiveRouteQuery, (v) =>
    String(v)
  );

  const { isLoading, isFetching, data, refetch } = useQuery({
    queryKey: ["cards", page, userId, type, isActive],
    queryFn: () =>
      cardRepo.getCards({
        page,
        perPage: 10,
        user_id: userId ? userId : undefined,
        type: type && (type === "bank" || type === "card") ? type : undefined,
        is_active: isActive ? JSON.parse(isActive) : undefined,
      }),
    refetchOnWindowFocus: false,
  });

  return {
    page,
    setPage,
    userId,
    setUserId,
    type,
    setType,
    isActive,
    setIsActive,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

export default useCardList;
