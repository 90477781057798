import { useMutation } from "react-query";
import { TNotificationForm } from "../../types/Notification/TNotificationForm";
import notificationRepo from "../../API/LayoutApi/notification.repo";
import { Button, Form, Input, Modal, Row, Space, Spin, message } from "antd";
import UserSelect from "../User/UserSelect";

function NotificationForm({
  open,
  setOpen,
  onSubmit,
}: {
  open?: boolean;
  setOpen?: (value: boolean) => void;
  onSubmit?: (formValues: TNotificationForm) => void;
}) {
  const { isLoading: loadingCreate, mutateAsync: mutateCreate } = useMutation({
    mutationFn: (formValues: TNotificationForm) =>
      notificationRepo.addNotification(formValues),
    onError: (error: string) => {
      message.error(error);
    },
  });

  async function onFinishForm(formValues: TNotificationForm) {
    try {
      await mutateCreate(formValues);
      onSubmit && onSubmit(formValues);
      setOpen && setOpen(false);
    } catch (err) {}
  }

  return (
    <Modal
      open={open}
      onCancel={() => setOpen && setOpen(false)}
      title="Add notification"
      footer={null}
      maskClosable={false}
    >
      <Spin spinning={loadingCreate}>
        <Form layout="vertical" onFinish={onFinishForm}>
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please notification title!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Text"
            name="text"
            rules={[{ required: true, message: "Please notification text!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Users"
            name="user_ids"
            rules={[
              {
                required: true,
                message: "Please select notification for users!",
              },
            ]}
          >
            <UserSelect multiple />
          </Form.Item>
          <Row justify="end">
            <Space>
              <Button onClick={() => setOpen && setOpen(false)}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default NotificationForm;
