import { TNotificationForm } from "./../../types/Notification/TNotificationForm";
import { TNotification } from "../../types/Notification/TNotification";
import { TPagination } from "../../types/common/TPagination";
import instance from "../api";
import { AxiosResponse } from "axios";

export type TGetNotificationsParams = {
  user_id?: string;
  is_read?: boolean;
  page: number;
  perPage: number;
};

const notificationRepo = {
  baseUrl: "admin/notifications",

  getNotifications(filterObject: TGetNotificationsParams) {
    const params: TGetNotificationsParams = { ...filterObject };

    if (!!filterObject.user_id) params.user_id = filterObject.user_id;
    if (!!filterObject.is_read) params.is_read = filterObject.is_read;

    return instance
      .get<TPagination<TNotification[]>>(this.baseUrl, {
        params,
      })
      .then((response) => response.data);
  },

  addNotification(values: TNotificationForm) {
    return instance
      .post<TNotificationForm, AxiosResponse<TNotification>>(
        this.baseUrl,
        values
      )
      .then((res) => res.data);
  },

  deleteNotification(id: string) {
    return instance.delete(`${this.baseUrl}/${id}`).then(() => {});
  },
};
export default notificationRepo;
