import useRouteQuery from "../common/useRouteQuery";
import { useQuery } from "react-query";
import providerRepo from "../../API/LayoutApi/provider.repo";
import { useState } from "react";

function useProviderList({
  pageRouteQuery = "page",
  businessIdRouteQuery = "business_id",
  statusRouteQuery = "status",
  searchRouteQuery = "search",
  isActiveRouteQuery = "is_active",
} = {}) {
  const [page, setPage] = useRouteQuery(1, pageRouteQuery, (v) => Number(v));
  const [businessId, setBusinessId] = useRouteQuery(
    undefined,
    businessIdRouteQuery,
    (v) => String(v)
  );
  const [status, setStatus] = useRouteQuery("", statusRouteQuery, (v) =>
    String(v)
  );
  const [search, setSearch] = useRouteQuery("", searchRouteQuery, (v) =>
    String(v)
  );
  const [isActive, setIsActive] = useRouteQuery("", isActiveRouteQuery, (v) =>
    String(v)
  );
  const [categoryIds, setCategoryIds] = useState<string[]>([]);

  const { isLoading, isFetching, data, refetch } = useQuery({
    queryKey: [
      "providers",
      page,
      businessId,
      status,
      search,
      categoryIds,
      isActive,
    ],
    queryFn: () =>
      providerRepo.getProviders({
        page,
        perPage: 10,
        business_id: businessId ? businessId : undefined,
        status: status ? status : undefined,
        search: search ? search : undefined,
        is_active: isActive ? JSON.parse(isActive) : undefined,
        category_ids:
          categoryIds && categoryIds.length > 0 ? categoryIds : undefined,
      }),
    refetchOnWindowFocus: false,
  });

  return {
    page,
    setPage,
    businessId,
    setBusinessId,
    status,
    setStatus,
    search,
    setSearch,
    isActive,
    setIsActive,
    categoryIds,
    setCategoryIds,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

export default useProviderList;
