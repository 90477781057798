import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { AutoComplete, Input, Spin } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import userRepo from "../../API/LayoutApi/user.repo";
import { useQuery } from "react-query";

function UserAutoComplete({
  value,
  onChange,
}: {
  value?: string;
  onChange?(val: string): void;
}) {
  const [searchUser, setSearchUser] = useState("");

  const { isFetching: isFetchingUser, data: userData } = useQuery({
    queryKey: ["users", searchUser],
    queryFn: () =>
      userRepo.getUsers({
        page: 1,
        perPage: 10,
        search: searchUser,
      }),
    refetchOnWindowFocus: false,
  });

  const [search, setSearch] = useState("");

  const options = useMemo(() => {
    if (userData?.data) {
      return userData.data.map((user) => ({
        label: user.first_name + " " + user.second_name,
        value: user.id,
      }));
    }
    return [];
  }, [userData]);

  function onSearch(val: string) {
    setSearch(val);
    debounceChangeSearchUser(val);
  }

  const debounceChangeSearchUser = useCallback(
    debounce(setSearchUser, 500),
    []
  );

  return (
    <AutoComplete
      value={search}
      options={options}
      onSelect={(value, option) => {
        if (onChange) {
          onChange(value);
        }
        setSearch(option.label);
      }}
      searchValue={search}
      onSearch={onSearch}
      onChange={(value: string) => setSearch(value)}
      onClear={() => onChange && onChange("")}
      allowClear
    >
      <Input
        placeholder="Search by user"
        addonAfter={isFetchingUser ? <LoadingOutlined /> : <UserOutlined />}
      />
    </AutoComplete>
  );
}

export default UserAutoComplete;
