import { Button, Row, Space, TablePaginationConfig, Tooltip } from "antd";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import SearchInput from "../../Utils/SearchInput";
import { FilterValue } from "antd/es/table/interface";
import useProviderList from "../../Hooks/Providers/useProviderList";
import BusinessSelect from "../Business/BusinessSelect";
import ProviderTable from "./ProvidersTable";
import providerRepo from "../../API/LayoutApi/provider.repo";
import CategorySelect from "../Category/CategorySelect";
import { useNavigate } from "react-router-dom";
import ProviderSetState from "./ProviderSetState";
import { useState } from "react";

function ProviderPage() {
  const {
    setPage,
    businessId,
    setBusinessId,
    status,
    setStatus,
    search,
    setSearch,
    setIsActive,
    categoryIds,
    setCategoryIds,
    isLoading,
    isFetching,
    data,
    refetch,
  } = useProviderList();

  function onChangeTable(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) {
    setPage(pagination.current as number);
    if (
      !filters.is_active ||
      (filters.is_active && filters.is_active.length === 2)
    ) {
      setIsActive("");
    } else if (filters.is_active && filters.is_active.length === 1) {
      setIsActive(String(filters.is_active[0]));
    }
  }

  const routerNavigate = useNavigate();

  function openForm(id = "0") {
    routerNavigate(`/provider-form/${id}`);
  }

  const [openSetState, setOpenSetState] = useState(false);
  const [providerId, setProviderId] = useState("");

  function openSetStateForm(providerId: string) {
    setProviderId(providerId);
    setOpenSetState(true);
  }

  return (
    <div>
      <Row align="top" justify="space-between" style={{ marginBottom: 16 }}>
        <Space>
          <SearchInput
            value={search}
            onDebounceChange={setSearch}
            placeholder="Search by name"
          />
          <SearchInput
            value={status}
            onDebounceChange={setStatus}
            placeholder="Search by status"
          />
          <BusinessSelect
            value={businessId}
            onChange={setBusinessId}
            style={{ width: 200 }}
          />
          <CategorySelect
            value={categoryIds}
            onChange={(value: string[]) => setCategoryIds(value)}
            style={{ width: 200 }}
            multiple
          />
        </Space>
        <Space>
          <Button
            onClick={() => openForm()}
            type="primary"
            icon={<PlusOutlined />}
          >
            Add Provider
          </Button>
          <Tooltip title="Refresh">
            <Button
              onClick={() => refetch()}
              loading={isFetching}
              icon={<SyncOutlined />}
            />
          </Tooltip>
        </Space>
      </Row>

      <ProviderTable
        data={data}
        isLoading={isLoading || isFetching}
        onChange={onChangeTable}
        onEdit={(provider) => openForm(provider.id)}
        onSetState={openSetStateForm}
        deleteData={(id: string, isActive) =>
          providerRepo.deleteProvider(id, isActive)
        }
        refetch={() => refetch()}
      />

      <ProviderSetState
        open={openSetState}
        setOpen={setOpenSetState}
        providerId={providerId}
      />
    </div>
  );
}

export default ProviderPage;
