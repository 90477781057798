import { useState } from "react";
import useCategoryList from "../../Hooks/Category/useCategoryList";
import { TCategory } from "../../types/Category/TCategory";
import { Button, Row, Space, TablePaginationConfig, Tooltip } from "antd";
import { FilterValue } from "antd/es/table/interface";
import SearchInput from "../../Utils/SearchInput";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import CategoryTable from "./CategoryTable";
import categoryRepo from "../../API/LayoutApi/category.repo";
import CategoryForm from "./CategoryForm";

function CategoryPage() {
  const {
    search,
    setSearch,
    setPage,
    setIsActive,
    data,
    refetch,
    isLoading,
    isFetching,
  } = useCategoryList();

  const [formOpen, setFormOpen] = useState(false);
  const [editingCategory, setEditingCategory] = useState<TCategory>();

  function openForm(category?: TCategory) {
    setFormOpen(true);
    setEditingCategory(category);
  }

  function onChangeTable(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) {
    setPage(pagination.current as number);
    if (
      !filters.is_active ||
      (filters.is_active && filters.is_active.length === 2)
    ) {
      setIsActive("");
    } else if (filters.is_active && filters.is_active.length === 1) {
      setIsActive(String(filters.is_active[0]));
    }
  }

  return (
    <div>
      <Row align="top" justify="space-between" style={{ marginBottom: 16 }}>
        <Space>
          <SearchInput
            value={search}
            onDebounceChange={setSearch}
            placeholder="Search"
            style={{ width: 250 }}
          />
        </Space>
        <Space>
          <Button
            onClick={() => openForm()}
            type="primary"
            icon={<PlusOutlined />}
          >
            Add Category
          </Button>
          <Tooltip title="Refresh">
            <Button
              onClick={() => refetch()}
              loading={isFetching}
              icon={<SyncOutlined />}
            />
          </Tooltip>
        </Space>
      </Row>

      <CategoryTable
        data={data}
        isLoading={isLoading || isFetching}
        onChange={onChangeTable}
        onEdit={openForm}
        deleteData={(id: string) => categoryRepo.deleteCategory(id)}
        refetch={() => refetch()}
      />

      {formOpen && (
        <CategoryForm
          open={formOpen}
          initialValues={editingCategory}
          id={editingCategory?.id}
          setOpen={setFormOpen}
          onSubmit={() => refetch()}
        />
      )}
    </div>
  );
}

export default CategoryPage;
