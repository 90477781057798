import { TBusinessUserForm } from "./../../types/BusinessUser/TBusinessUserForm";
import { TAddUser } from "./../../types/User/TAddUser";
import { TUser } from "../../types/User/TUser";
import { TPagination } from "../../types/common/TPagination";
import instance from "../api";
import { TBusinessUser } from "../../types/BusinessUser/TBusinessUser";

export type TGetBusinessUsersParams = {
  business_id?: string;
  email?: string;
  phone?: string;
  search?: string;
  is_active?: boolean;
  page: number;
  perPage: number;
};

const businessUserRepo = {
  baseUrl: "admin-business-users",
  getBusinessUsers(filterObject: TGetBusinessUsersParams) {
    const params: TGetBusinessUsersParams = { ...filterObject };

    if (!!filterObject.business_id)
      params.business_id = filterObject.business_id;
    if (!!filterObject.email) params.email = filterObject.email;
    if (!!filterObject.phone) params.phone = filterObject.phone;
    if (!!filterObject.search) params.search = filterObject.search;
    if (!!filterObject.is_active) params.is_active = filterObject.is_active;

    return instance
      .get<TPagination<TBusinessUser[]>>(this.baseUrl, {
        params,
      })
      .then((response) => response.data);
  },

  addBusinessUser(formValues: TBusinessUserForm) {
    return instance.post(this.baseUrl, formValues).then(() => {});
  },

  businessUserEdit(id: string, formValues: TBusinessUserForm) {
    const editingData = { ...formValues };
    if (!!editingData.password?.trim()) {
      delete editingData.password;
    }
    return instance.put(`${this.baseUrl}/${id}`, editingData).then(() => {});
  },

  businessUserDelete(id: string) {
    return instance.delete(`${this.baseUrl}/${id}`).then(() => {});
  },
};

export default businessUserRepo;
