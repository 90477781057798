import { Table, TablePaginationConfig, Tag, Tooltip } from "antd";
import moment from "moment";
import { TCard } from "../../types/Card/TCard";
import { TPagination } from "../../types/common/TPagination";
import { FilterValue } from "antd/es/table/interface";
import { QueryObserverResult } from "react-query";

const CardTable = ({
  data,
  onEdit,
  onChange,
  isLoading,
  deleteData,
  refetch,
}: {
  data?: TPagination<TCard[]>;
  onEdit(useditingDataer: TCard): void;
  onChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ): void;
  isLoading?: boolean;
  deleteData(id: string): Promise<void>;
  refetch(): Promise<QueryObserverResult<TPagination<TCard[]>, unknown>>;
}) => {
  return (
    <Table
      onChange={onChange}
      dataSource={data?.data?.map((u, i) => ({
        no: i + 1,
        brand: u?.brand,
        number: u?.number,
        currency: u?.currency,
        cvv: u?.cvv,
        expire_month: u?.expire_month,
        expire_year: u?.expire_year,
        created_at: u.created_at
          ? moment(u.created_at).format("YYYY-MM-DD")
          : "",
        is_active: u?.is_active,
        is_currency: u?.is_currency,
        last4: u?.last4,
        status: u?.status,
        action: { id: u.id },
        key: u.id,
      }))}
      columns={[
        {
          title: "No",
          dataIndex: "no",
          key: "no",
        },
        {
          title: "Brand",
          dataIndex: "brand",
          key: "brand",
        },
        {
          title: "Number",
          dataIndex: "number",
          key: "number",
        },
        {
          title: "CVV",
          dataIndex: "cvv",
          key: "cvv",
        },
        {
          title: "Expire month",
          dataIndex: "expire_month",
          key: "expire_month",
        },
        {
          title: "Expire year",
          dataIndex: "expire_year",
          key: "expire_year",
        },
        {
          title: "Currency",
          dataIndex: "currency",
          key: "currency",
        },
        {
          title: "Last4",
          dataIndex: "last4",
          key: "last4",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Created at",
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: "Is currency",
          dataIndex: "is_currency",
          key: "is_currency",
          render: (tag: boolean) => (
            <Tag color={tag ? "geekblue" : "red"}>{tag ? "True" : "False"}</Tag>
          ),
        },
        {
          title: "Is Active",
          dataIndex: "is_active",
          key: "is_active",
          render: (tag: boolean) => (
            <Tag color={tag ? "geekblue" : "red"}>{tag ? "True" : "False"}</Tag>
          ),
          filters: [
            {
              text: "True",
              value: true,
            },
            {
              text: "False",
              value: false,
            },
          ],
        },
        // {
        //   title: "Actions",
        //   dataIndex: "action",
        //   key: "action",
        //   render: ({ id }: { id: string }) => (
        //     <Space>
        //       <Tooltip title="Edit Card">
        //         <Button
        //           onClick={() => {
        //             if (data?.data) {
        //               const device = data.data.find(
        //                 (device) => device.id === id
        //               );
        //               if (device) {
        //                 onEdit(device);
        //               }
        //             }
        //           }}
        //           icon={<EditOutlined />}
        //         />
        //       </Tooltip>

        //       <Tooltip title="Delete Card">
        //         <Button
        //           icon={<DeleteOutlined />}
        //           onClick={() =>
        //             Modal.confirm({
        //               title: "Card",
        //               icon: <ExclamationCircleFilled />,
        //               content: "Do you want to delete this card ?",
        //               onOk: () => deleteData(id).then(() => refetch()),
        //             })
        //           }
        //           danger
        //         />
        //       </Tooltip>
        //     </Space>
        //   ),
        // },
      ]}
    />
  );
};

export default CardTable;
