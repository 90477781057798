import { TAddLocation } from "./../../types/Location/TAddLocation";
import { TLocation } from "../../types/Location/TLocation";
import { TPagination } from "../../types/common/TPagination";
import instance from "../api";

export type TGetLocationsParams = {
  user_id?: string;
  search?: string;
  is_active?: boolean;
  page: number;
  perPage: number;
};

const locationRepo = {
  baseUrl: "admin/locations",
  getLocations(filterObject: TGetLocationsParams) {
    const params: TGetLocationsParams = { ...filterObject };

    if (!!filterObject.user_id) params.user_id = filterObject.user_id;
    if (!!filterObject.search) params.search = filterObject.search;
    if (!!filterObject.is_active) params.is_active = filterObject.is_active;

    return instance
      .get<TPagination<TLocation[]>>(this.baseUrl, {
        params,
      })
      .then((response) => response.data);
  },

  addLocation(addObject: TAddLocation) {
    return instance.post(this.baseUrl, addObject).then(() => {});
  },

  locationEdit(id: string, editObject: Omit<TLocation, "id">) {
    return instance.put(`${this.baseUrl}/${id}`, editObject).then(() => {});
  },

  locationDelete(id: string) {
    return instance.delete(`${this.baseUrl}/${id}`).then(() => {});
  },
};

export default locationRepo;
