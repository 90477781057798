import { Button, Row, Space, TablePaginationConfig, Tooltip } from "antd";
import useNotificationList from "../../Hooks/Notifications/useNotificationList";
import { FilterValue } from "antd/es/table/interface";
import UserAutoComplete from "../User/UserAutoComplete";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import NotificationTable from "./NotificationTable";
import notificationRepo from "../../API/LayoutApi/notification.repo";
import { useState } from "react";
import NotificationForm from "./NotificationForm";

function NotificationPage() {
  const {
    userId,
    setUserId,
    setPage,
    setIsRead,
    isLoading,
    isFetching,
    data,
    refetch,
  } = useNotificationList();

  function onChangeTable(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) {
    setPage(pagination.current as number);
    if (!filters.is_read || (filters.is_read && filters.is_read.length === 2)) {
      setIsRead("");
    } else if (filters.is_read && filters.is_read.length === 1) {
      setIsRead(String(filters.is_read[0]));
    }
  }

  const [formOpen, setFormOpen] = useState(false);

  return (
    <div>
      <Row align="top" justify="space-between" style={{ marginBottom: 16 }}>
        <Space>
          <UserAutoComplete value={userId} onChange={setUserId} />
        </Space>
        <Space>
          <Button
            onClick={() => setFormOpen(true)}
            type="primary"
            icon={<PlusOutlined />}
          >
            Add Notification
          </Button>
          <Tooltip title="Refresh">
            <Button
              onClick={() => refetch()}
              loading={isFetching}
              icon={<SyncOutlined />}
            />
          </Tooltip>
        </Space>
      </Row>
      <NotificationTable
        data={data}
        isLoading={isLoading || isFetching}
        onChange={onChangeTable}
        deleteData={(id: string) => notificationRepo.deleteNotification(id)}
        refetch={() => refetch()}
      />

      <NotificationForm
        open={formOpen}
        setOpen={setFormOpen}
        onSubmit={() => refetch()}
      />
    </div>
  );
}

export default NotificationPage;
