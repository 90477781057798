import { Button, Form, Input, Modal, Row, Space, Spin, message } from "antd";
import { TAddUser } from "../../types/User/TAddUser";
import { useMutation } from "react-query";
import userRepo from "../../API/LayoutApi/user.repo";

export type TUserAddFormProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSubmit: (formValues: TAddUser) => void;
};

function UserAddForm({ open, setOpen, onSubmit }: TUserAddFormProps) {
  const [form] = Form.useForm();
  const { isLoading, mutateAsync: mutateUserCreate } = useMutation({
    mutationFn: (addUserObject: TAddUser) => userRepo.addUser(addUserObject),
    onSuccess: () => {
      message.success("User created successfully!");
      setOpen(false);
      form.resetFields();
    },
    onError: (error: string) => {
      message.error(error);
    },
  });

  function onFinishForm(values: TAddUser) {
    mutateUserCreate(values).then(() => {
      onSubmit(values);
    });
  }

  function onCancel() {
    setOpen(false);
    form.resetFields();
  }

  return (
    <Modal
      open={open}
      title="Add User"
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <Form layout="vertical" onFinish={onFinishForm} form={form}>
          <Form.Item<TAddUser>
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<TAddUser>
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item<TAddUser>
            label="First name"
            name="first_name"
            rules={[
              { required: true, message: "Please input your first name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item<TAddUser>
            label="Second name"
            name="second_name"
            rules={[
              { required: true, message: "Please input your second name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item<TAddUser>
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<TAddUser>
            label="Phone"
            name="phone"
            rules={[{ required: true, message: "Please input your phone!" }]}
          >
            <Input />
          </Form.Item>
          <Row justify="end">
            <Space>
              <Button onClick={() => onCancel()}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default UserAddForm;
