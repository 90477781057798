import { Button, Row, Space, TablePaginationConfig, Tooltip } from "antd";
import SearchInput from "../../Utils/SearchInput";
import useBusinessList from "../../Hooks/Business/useBusinessList";
import { useState } from "react";
import { FilterValue } from "antd/es/table/interface";
import { TBusiness } from "../../types/Business/TBusiness";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import BusinessTable from "./BusinessTable";
import businessRepo from "../../API/LayoutApi/business.repo";
import BusinessForm from "./BusinessForm";

function BusinessPage() {
  const {
    search,
    setSearch,
    setPage,
    setIsActive,
    data,
    refetch,
    isLoading,
    isFetching,
  } = useBusinessList();

  const [formOpen, setFormOpen] = useState(false);
  const [editingBusiness, setEditingBusiness] = useState<TBusiness>();

  function openForm(business?: TBusiness) {
    setFormOpen(true);
    setEditingBusiness(business);
  }

  function onChangeTable(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) {
    setPage(pagination.current as number);
    if (
      !filters.is_active ||
      (filters.is_active && filters.is_active.length === 2)
    ) {
      setIsActive("");
    } else if (filters.is_active && filters.is_active.length === 1) {
      setIsActive(String(filters.is_active[0]));
    }
  }

  return (
    <div>
      <Row align="top" justify="space-between" style={{ marginBottom: 16 }}>
        <Space>
          <SearchInput
            value={search}
            onDebounceChange={setSearch}
            placeholder="Search"
            style={{ width: 250 }}
          />
        </Space>
        <Space>
          <Button
            onClick={() => openForm()}
            type="primary"
            icon={<PlusOutlined />}
          >
            Add Business
          </Button>
          <Tooltip title="Refresh">
            <Button
              onClick={() => refetch()}
              loading={isFetching}
              icon={<SyncOutlined />}
            />
          </Tooltip>
        </Space>
      </Row>
      <BusinessTable
        data={data}
        isLoading={isLoading || isFetching}
        onChange={onChangeTable}
        onEdit={openForm}
        deleteData={(id: string) => businessRepo.deleteBusiness(id)}
        refetch={() => refetch()}
      />

      {formOpen && (
        <BusinessForm
          open={formOpen}
          setOpen={setFormOpen}
          id={editingBusiness?.id}
          name={editingBusiness?.name}
          filePath={editingBusiness?.file_path}
          onSubmit={() => refetch()}
        />
      )}
    </div>
  );
}

export default BusinessPage;
