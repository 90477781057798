import { Button, Form, Input, Modal, Row, Space, Spin, message } from "antd";
import { useMutation } from "react-query";
import { THistory } from "../../types/History/THistory";
import { THistoryForm } from "../../types/History/THistoryForm";
import historyRepo from "../../API/LayoutApi/history.repo";
import UserAutoComplete from "../User/UserAutoComplete";

function HistoryForm({
  id,
  open,
  initialValues,
  setOpen,
  onSubmit,
}: {
  id?: string;
  open?: boolean;
  initialValues?: THistory;
  setOpen?: (value: boolean) => void;
  onSubmit?: (formValues: THistoryForm) => void;
}) {
  const { isLoading: loadingCreate, mutateAsync: mutateCreate } = useMutation({
    mutationFn: (formValues: THistoryForm) =>
      historyRepo.addHistory(formValues),
    onError: (error: string) => {
      message.error(error);
    },
  });
  const { isLoading: loadingUpdate, mutateAsync: mutateUpdate } = useMutation({
    mutationFn: ({
      id,
      formValues,
    }: {
      id: string;
      formValues: THistoryForm;
    }) => historyRepo.historyEdit(id, formValues),
    onError: (error: string) => {
      message.error(error);
    },
  });

  async function onFinishForm(formValues: THistoryForm) {
    try {
      !id
        ? await mutateCreate(formValues)
        : await mutateUpdate({ id, formValues });
      onSubmit && onSubmit(formValues);
      setOpen && setOpen(false);
    } catch (err) {}
  }

  return (
    <Modal
      open={open}
      onCancel={() => setOpen && setOpen(false)}
      title={id ? "Edit History" : "Add History"}
      footer={null}
      maskClosable={false}
    >
      <Spin spinning={loadingCreate || loadingUpdate}>
        <Form
          layout="vertical"
          initialValues={initialValues}
          onFinish={onFinishForm}
        >
          <Form.Item
            label="History name"
            name="name"
            rules={[
              { required: true, message: "Please input your history name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Cheque id"
            name="cheque_id"
            rules={[
              { required: true, message: "Please input your cheque id!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="User"
            name="user_id"
            rules={[{ required: true, message: "Please select your user!" }]}
          >
            <UserAutoComplete />
          </Form.Item>
          <Row justify="end">
            <Space>
              <Button onClick={() => setOpen && setOpen(false)}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default HistoryForm;
