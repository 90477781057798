import {
  Button,
  Modal,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
} from "antd";
import {
  ExclamationCircleFilled,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { TPagination } from "../../types/common/TPagination";
import { TBusiness } from "../../types/Business/TBusiness";
import { FilterValue } from "antd/es/table/interface";
import { QueryObserverResult } from "react-query";

const BusinessTable = ({
  data,
  onEdit,
  onChange,
  isLoading,
  deleteData,
  refetch,
}: {
  data?: TPagination<TBusiness[]>;
  onEdit?(business: TBusiness): void;
  onChange?(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ): void;
  isLoading?: boolean;
  deleteData?(id: string): Promise<void>;
  refetch?(): Promise<QueryObserverResult<TPagination<TBusiness[]>, unknown>>;
}) => {
  return (
    <Table
      loading={isLoading}
      onChange={onChange}
      pagination={{
        total: data?.total,
      }}
      dataSource={data?.data?.map((u, i) => ({
        no: i + 1,
        name: u?.name,
        file_path: u?.file_path,
        created_at: u.created_at
          ? moment(u.created_at).format("YYYY-MM-DD")
          : "",
        action: { id: u.id },
        key: u.id,
      }))}
      columns={[
        {
          title: "#",
          dataIndex: "no",
          key: "no",
        },
        {
          title: "name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "file_path",
          dataIndex: "file_path",
          key: "file_path",
        },
        {
          title: "created_at",
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: "Is Active",
          dataIndex: "is_active",
          key: "is_active",
          render: (tag: boolean) => (
            <Tag color={tag ? "geekblue" : "red"}>{tag ? "True" : "False"}</Tag>
          ),
          filters: [
            {
              text: "True",
              value: true,
            },
            {
              text: "False",
              value: false,
            },
          ],
        },
        {
          title: "Actions",
          dataIndex: "action",
          key: "action",
          render: ({ id }: { id: string }) => (
            <Space>
              <Tooltip title="Edit business">
                <Button
                  onClick={() => {
                    if (data?.data) {
                      const business = data.data.find(
                        (business) => business.id === id
                      );
                      if (business) {
                        onEdit && onEdit(business);
                      }
                    }
                  }}
                  icon={<EditOutlined />}
                />
              </Tooltip>

              <Tooltip title="Delete business">
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    Modal.confirm({
                      title: "Business",
                      icon: <ExclamationCircleFilled />,
                      content: "Do you want to delete this business?",
                      onOk: () =>
                        deleteData && deleteData(id).then(() => refetch),
                    })
                  }
                  danger
                />
              </Tooltip>
            </Space>
          ),
        },
      ]}
    />
  );
};

export default BusinessTable;
